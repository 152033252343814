import { useState, useEffect } from 'react'
import { ReactComponent as WelcomeWizardImageLg } from "../../assets/img/img-welcome-wizard-lg.svg";
import { ReactComponent as LogoBg } from "../../assets/img/img-logo-bg.svg";
import { Stepper, Step } from 'react-form-stepper';
import { useNavigate } from "react-router-dom";

import { toast } from 'react-toastify'

function Preferences(props: any) {
    const navigate = useNavigate();
    const [productiveTime, setProductiveTime] = useState<any>(-1);

    function setUserPreferences() {
        if (productiveTime === -1) {
            toast.error('Please fill in your productive time.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            var user = {
                productiveTime: productiveTime
            }
            var options = {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify(user)
            }
            fetch('https://mindy-api.azurewebsites.net/Employee', options)
                .then(function (res) {
                    console.log(res);
                })
                .then(function (resJson) {
                    console.log(resJson);
                    navigate("/");
                })
        }
    }

    const handleProductiveTimeChange = (event:any) => {
        const value = parseInt(event.target.value); // Parse the value as an integer
        setProductiveTime(value); // Update the selected option in state
    };

    return (
        <div className="preferences">
            <WelcomeWizardImageLg className="preferences__bg" />
            <LogoBg className="preferences__bg--logo" />
            <div className="preferences__stepper">
                {/* <h2 className="preferences__stepper__title">Overview</h2> */}
                {/* <Stepper connectorStateColors={true} connectorStyleConfig={{ size: 2, disabledColor: "#FFFFFF", activeColor: "#01195D", completedColor: "#01195D", style: 'solid' }} className="welcome-wizard__stepper" stepClassName="welcome-wizard__stepper__step" activeStep={2}>
                    <Step label="Introduction" />
                    <Step label="Survey" />
                    <Step label="Preferences" />
                </Stepper> */}
            </div>

            <div className="preferences__container">
                <h2 className="preferences__container__title">We have a couple more questions for you:</h2>
                <p className="preferences__container__question">What time during the day are you the most productive?</p>
                <label className="preferences__container__label">
                    <select value={productiveTime} onChange={handleProductiveTimeChange}>
                        <option value={-1} disabled>Please select time.</option>
                        <option value={0}>Before Noon</option>
                        <option value={1}>After Noon</option>
                    </select>
                </label>
                {/*<p className="preferences__container__question">What are your typical working hours?</p>
                <label className="preferences__container__label">
                    <input type={"time"} value={workDayStart} onChange={(e) => {setWorkDayStart(e.currentTarget.value);}}></input>
                    to
                    <input type={"time"} value={workDayEnd} onChange={(e) => {setWorkDayEnd(e.currentTarget.value);}}></input>
                </label>
                <p className="preferences__container__question">How much time do you spend on breaks during the morning?</p>
                <label className="preferences__container__label">
                    <input type={"number"} value={morningBreakMinutes} onChange={(e) z=> {setMorningBreakMinutes(e.currentTarget.value);}}></input>
                    minutes
                </label>
                <p className="preferences__container__question">How much time do you take during lunch break?</p>
                <label className="preferences__container__label">
                    <input type={"number"} value={lunchBreakMinutes} onChange={(e) => {setLunchBreakMinutes(e.currentTarget.value);}}></input>
                    minutes
                </label>
                <p className="preferences__container__question">How much time do you spend on breaks during the afternoon?</p>
                <label className="preferences__container__label">
                    <input type={"number"} value={afternoonBreakMinutes} onChange={(e) => {setAfternoonBreakMinutes(e.currentTarget.value);}}></input>
                    minutes
                </label> */}

                <button className="preferences__container__btn" onClick={setUserPreferences} >Proceed</button>
            </div>
        </div>
    )
}

export default Preferences;
