import { useEffect, useRef, useState } from 'react'
import { ReactComponent as SentimentCounterIcon } from '../../assets/icons/icon-sentiment-counter.svg';

import { Bar, CartesianGrid, Cell, ComposedChart, Label, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import DropdownSentiment from "./DropdownSentiment";


type ViewBox = {
    cx: number;
    cy: number;
};

type CustomLabelProps = {
    viewBox: ViewBox;
    peopleAnswered: number;
};

function Sentiment(props: any) {

    const queryStringRef = useRef<string>('');
    const [sentiments, setSentiments] = useState<any>();
    const [sentimentHistory, setSentimentHistory] = useState<any>();
    const [selectedValueSentimentdropdown, setSelectedValueSentimentDropdown] = useState<any>(6);
    const [activeSentiment, setActiveSentiment] = useState<any>(6);
    const [peopleQuestioned, setPeopleQuestioned] = useState<any>(0);
    const [peopleAnswered, setPeopleAnswered] = useState<any>(0);
    const [engagementData, setEngagementData] = useState<any>();

    useEffect(() => {
        const queryString = `?departments=${props.filters.departments}&entities=${props.filters.entities}&jobTitles=${props.filters.jobTitles}&countries=${props.filters.countries}`;
        queryStringRef.current = queryString;
        getSentiment(queryString);
        getSentimentById(selectedValueSentimentdropdown, props.filters.view, queryString);
    }, [props.filters])

    const getSentiment = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/sentiment" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setSentiments(data);
        setPeopleQuestioned(data.peopleQuestioned);
        setPeopleAnswered(data.peopleAnswered);

        const apiEngagementData = data.averages.slice(0, 2).map((item: any, index: number) => ({
            name: `sentiment${index + 1}`,
            value: item.percentage,
            fill: index === 0 ? "#3E4CC7" : "#6070FF",
        }));

        const totalPercentageOther = data.averages.slice(2).reduce((total: any, item: any) => total + item.percentage, 0);

        apiEngagementData.push({
            name: "other",
            value: totalPercentageOther,
            fill: "#99A3BE",
        });

        setEngagementData(apiEngagementData);
    }

    const getSentimentById = async (emotionId: any, view: string, queryString: string) => {

        console.log(`Getting sentiment balance for view: ${view}`);
        const apiUrl = `https://mindy-api.azurewebsites.net/sentiment/${view}?emotionId=${emotionId}`;
        const apiUrlFilter = `https://mindy-api.azurewebsites.net/sentiment/${view}${queryString}&emotionId=${emotionId}`;
        const fetchSentimentData = async (url: any, options: any) => {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(response);
            console.log(data);
            return data;
        };

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            },
        };

        const data = await fetchSentimentData(apiUrl, options)
        var averages = data.averages.map((item: any) => ({ name: item.name, percentage: item.emotions[0].percentage }));
        console.log("averages: " + JSON.stringify(averages));


        if (props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) {
            const filteredData = await fetchSentimentData(apiUrlFilter, options);

            for (let i = 0; i < filteredData.averages.length; i++) {
                averages[i].percentageFilter = filteredData.averages[i].emotions[0].percentage;
            }

            console.log(`Data after adding filter: ${JSON.stringify(averages)}`);

        }
        setSentimentHistory(averages);
    }

    const handleDropdownChange = async (event: any) => {
        setSelectedValueSentimentDropdown(event.target.value);


        console.log(`Getting sentiment balance for view: ` + props.filters.view);
        const apiUrl = `https://mindy-api.azurewebsites.net/sentiment/${props.filters.view}?emotionId=${event.target.value}`;
        const apiUrlFilter = `https://mindy-api.azurewebsites.net/sentiment/${props.filters.view}${queryStringRef.current}&emotionId=${event.target.value}`;
        const fetchSentimentData = async (url: any, options: any) => {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(response);
            console.log(data);
            return data;
        };

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            },
        };

        const data = await fetchSentimentData(apiUrl, options)
        var averages = data.averages.map((item: any) => ({ name: item.name, percentage: item.emotions[0].percentage }));
        console.log("averages: " + JSON.stringify(averages));


        if (props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) {
            const filteredData = await fetchSentimentData(apiUrlFilter, options);

            for (let i = 0; i < filteredData.averages.length; i++) {
                averages[i].percentageFilter = filteredData.averages[i].emotions[0].percentage;
            }

            console.log(`Data after adding filter: ${JSON.stringify(averages)}`);

        }

        setSentimentHistory(averages);
    }

    function CustomLabel({ viewBox, peopleAnswered }: CustomLabelProps) {
        const { cx, cy } = viewBox;
        return (
            <text
                x={cx}
                y={cy}
                fill="#3d405c"
                className="recharts-text recharts-label"
                textAnchor="middle"
                dominantBaseline="central"
            >
                <tspan alignmentBaseline="middle" fontSize="56" fontWeight={900}>
                    {peopleAnswered}
                </tspan>
                <tspan fontSize="12" x={cx} y={cy + 30} fontWeight={800}>
                    EMPLOYEES
                </tspan>
            </text>
        );
    }

    return (
        <div className="sentiment">
            <section className="sentiment__stats">
                <h2 className="sentiment__stats__title">Mindthriven's <span>Sentiments</span></h2>
                <div className="sentiment__stats__container">
                    <div className="sentiment__stats__container__counter">
                        <div className="sentiment__stats__container__counter__graph">
                            {(!sentimentHistory) ? ("Loading...") : (
                                <PieChart width={200} height={200}>
                                    <Pie
                                        data={engagementData}
                                        dataKey="value"
                                        cx={100}
                                        cy={100}
                                        innerRadius={70}
                                        outerRadius={90}
                                        startAngle={0}
                                        endAngle={360}
                                        cornerRadius={40}
                                        paddingAngle={10}
                                    >
                                        <Label
                                            width={30}
                                            position="center"
                                            content={<CustomLabel viewBox={{ cx: 100, cy: 100 }} peopleAnswered={peopleAnswered} />}
                                        ></Label>
                                    </Pie>
                                </PieChart>
                            )}

                            {/* <SentimentCounterIcon className="sentiment__stats__container__counter__graph__bg" />
                            <h2 className="sentiment__stats__container__counter__graph__title">0<span>employees</span></h2> */}
                        </div>
                        <div className="sentiment__stats__container__counter__engagement">
                            <h2 className="sentiment__stats__container__counter__engagement__title">user engagement</h2>
                            <p className="sentiment__stats__container__counter__engagement__text">{peopleQuestioned} People questioned</p>
                            <progress className="sentiment__stats__container__counter__engagement__progress" value={peopleAnswered} max={peopleQuestioned}></progress>
                        </div>
                    </div>

                    <ol className="sentiment__stats__container__list">
                        {
                            (!sentiments) ? ("loading...") : (
                                sentiments.averages.map((sentiment: any) => (
                                    <li>{sentiment.percentage}% {sentiment.emotion}</li>
                                ))
                            )
                        }
                    </ol>
                </div>
            </section>
            <section className="sentiment__graph">
                <div className="sentiment__graph__header">
                    <h2 className="sentiment__graph__header__title">Sentiment <span>analyse</span></h2>
                    <DropdownSentiment sentiments={sentiments} selectedValue={selectedValueSentimentdropdown} onDropdownChange={handleDropdownChange} />
                </div>
                {(!sentimentHistory) ? ("Loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.6}>
                        <ComposedChart data={sentimentHistory} >
                            <XAxis dataKey="name" />
                            <YAxis width={25}/>
                            <Tooltip />
                            <Legend />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="percentage" barSize={30} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            {(props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) ? (
                                <Bar dataKey="percentageFilter" barSize={30} fill="#086DFD" legendType="none" radius={[4, 4, 0, 0]} />
                            ) : ("")}
                            {/* <Line type="monotone" dataKey="average" stroke="#01195D" label="average" legendType="line" /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}

            </section>
        </div>
    )
}

export default Sentiment