import { useState, useEffect } from 'react'
import { ReactComponent as ChargeIcon } from "../../assets/icons/icon-charge.svg"
import { ReactComponent as ReportIcon } from "../../assets/icons/icon-report.svg";
import MeterProps from "../../assets/img/meter-props.png";
import { ReactComponent as MeterNeedle } from "../../assets/img/meter-needle.svg";
import { ReactComponent as UserIcon } from '../../assets/icons/icon-user-small.svg';
import { Area, Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { ReactComponent as MeetingsIcon } from "../../assets/icons/icon-meetings.svg";
import { ReactComponent as BreaksIcon } from "../../assets/icons/icon-breaks.svg";
import { ReactComponent as FocusIcon } from "../../assets/icons/icon-focus.svg";
import { ReactComponent as PrivateIcon } from "../../assets/icons/icon-private.svg";

import { ReactComponent as GarbageIcon } from "../../assets/icons/advices/icon-garbage.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/advices/icon-target.svg";

import { ReactComponent as LineIcon } from "../../assets/icons/icon-line.svg";
import Dropdown from "../general/Dropdown";
import IconsMetrics from "../general/IconsMetrics";
import React from "react";
import IconsAdvices from "../general/IconsAdvices";
import { useModal } from '../general/useModal';
import { ModalNoData } from '../general/ModalNoData';
import { toast } from 'react-toastify';
import { ModalQuestSurvey } from './ModalQuestSurvey';
import { ModalQuestResults } from './ModalQuestResults';
import { ModalQuestDelete } from './ModalQuestDelete';

// import Swiper core and required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function EnergyDashboard(props: any) {
    const [user, setUser] = useState<any>(props.user);
    const [energyHistory, setEnergyHistory] = useState<any>();
    const [energyHistoryWeek, setEnergyHistoryWeek] = useState<any>();
    const [advice, setAdvice] = useState<any>();
    const [activityReport, setActivityReport] = useState<any>();
    const [activityReportData, setActivityReportData] = useState<any>();
    const [selectedValueDropdown, setSelectedValueDropdown] = useState<any>(1);
    const [questSurvey, setQuestSurvey] = useState<any>();
    const [questAdvice, setQuestAdvice] = useState<any>();
    const [questFinish, setQuestFinish] = useState<boolean>(false);
    const [questResults, setQuestResults] = useState<any>();
    const { isShown: isModalNoDataShown, toggle: toggleNoDataModal } = useModal();
    const { isShown: isModalQuestSurveyShown, toggle: toggleQuestSurveyModal } = useModal();
    const { isShown: isModalQuestResultsShown, toggle: toggleQuestResultsModal } = useModal();
    const { isShown: isModalQuestDelteShown, toggle: toggleQuestDeleteModal } = useModal();

    useEffect(() => {
        getEnergyBalance();
        getEnergyBalanceWeek();
        getAdvice();
        getActivityReport();
        if (user && user.inFirstWeek) {
            toggleNoDataModal();
        }
    }, [])

    const getEnergyBalance = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setEnergyHistory(data);
    }

    const getEnergyBalanceWeek = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory/week?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        console.log(data.dailyAverages)
        setEnergyHistoryWeek(data);
    }

    const getAdvice = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/advice?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        console.log(data[0]);
        setAdvice(data);
    }

    const getActivityReport = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/activityReport?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        console.log(data[0]);
        setActivityReport(data);

    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (props: any) => {
        const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
        const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
        const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);
        console.log("props customized label: " + JSON.stringify(props))
        return (
            <svg x={x - 10} y={y - 10} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                {(props.payload.payload.percent === 0) ? (
                    ""
                ) : (props.payload.payload.name == "Breaks") ? (
                    <BreaksIcon height={14} width={14} />
                ) : (props.payload.payload.name == "privateEvents") ? (
                    <PrivateIcon height={15} width={12} />
                ) : (props.payload.payload.name == "Focus") ? (
                    <FocusIcon height={15} width={15} />
                ) : (props.payload.payload.name === "Meetings") ? (
                    <MeetingsIcon height={16} width={18} />
                ) : ("")}
                {/* {(props.payload.payload.percent === 0) ? (
                    ""
                ) : (
                    // <text x="5.5" y="45" dominantBaseline="central" fill={props.payload.payload.color}>{props.payload.payload.percentage}</text>
                    <path x="" d="M15.8297 26.3948C13.9972 26.6069 12.1401 26.4035 10.3987 25.8002C8.65721 25.1968 7.07706 24.2093 5.77764 22.9121C4.3073 21.4557 3.23427 19.6535 2.65905 17.6743C2.08384 15.6951 2.02528 13.6036 2.48885 11.5959C2.95242 9.58814 3.92296 7.72979 5.30954 6.19492C6.69612 4.66005 8.45337 3.49889 10.4167 2.82022C12.38 2.14155 14.4851 1.96757 16.5348 2.31458C18.5845 2.66159 20.5118 3.51824 22.136 4.80428C23.7603 6.09032 25.0283 7.76366 25.8215 9.66754C26.6146 11.5714 26.9068 13.6436 26.6707 15.6898M14.4647 7.5482V14.3081L17.1947 17.0121M21.2898 21.068V27.8279M26.7499 21.068V27.8279" stroke="#C174FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                )} */}
            </svg>
        );
    };

    const customTooltip = (props: any) => {
        const { payload } = props;
        if (payload && payload.length) {
            console.log("payload tooltip :" + JSON.stringify(payload));

            return (
                <div style={{ backgroundColor: 'white', padding: 8 }}>
                    <p>{payload[0].payload.name}: {payload[0].payload.avgEnergyBalance}</p>
                    {/* <p>Your Average: {payload[0].payload.globalAvgEnergyBalance}</p> */}
                </div>
            );
        }
        return null;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        console.log("payload legend :" + JSON.stringify(payload));

        return (
            <ul style={{ display: "flex", justifyContent: "center" }}>
                {
                    payload.map((entry: any, index: any) => {
                        console.log("Entry legend:" + JSON.stringify(entry));

                        if (entry.payload.legendType !== 'none') {
                            return (
                                <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", fontSize: 8, color: '#01195D', fontWeight: 700, gap: 8 }}><LineIcon />YOUR AVERAGE</li>
                            );
                        }

                    })
                }
            </ul>
        );
    }

    const handleDropdownChange = async (event: any) => {
        console.log("dropdown change value: " + event.target.value);
        setSelectedValueDropdown(event.target.value);


        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        if (event.target.value === "week") {
            const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory/week?userId=" + user.userId, options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setEnergyHistoryWeek(data);
        } else if (event.target.value === "month") {
            const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory/month?userId=" + user.userId, options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setEnergyHistoryWeek(data);
        } else if (event.target.value == "year") {
            const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory/year?userId=" + user.userId, options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setEnergyHistoryWeek(data);
        }
    }

    const startQuest = async (advice: any) => {

        try {
            var options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify({ adviceId: advice.id })
            }

            const response = await fetch("https://mindy-api.azurewebsites.net/Quest/PreStart", options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setQuestSurvey(data.questSurvey);
            setQuestAdvice(advice)

            toggleQuestSurveyModal();
        } catch (error) {
            toast.success("Quest start failed.");
        }

    }

    const finishQuest = async (advice: any) => {
        setQuestFinish(true);
        try {
            var options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify({ adviceId: advice.id })
            }

            const response = await fetch("https://mindy-api.azurewebsites.net/Quest/PreFinish", options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setQuestSurvey(data.questSurvey);
            setQuestAdvice(advice)

            toggleQuestSurveyModal();
        } catch (error) {
            toast.success("Quest finish failed.");
        }

    }

    const deleteQuest = async (advice: any) => {
        setQuestAdvice(advice)
        toggleQuestDeleteModal();
    }

    return (
        <div className={`energy-dashboard-employee ${user && user.inFirstWeek ? 'blur' : ''}`}>
            <ModalNoData isShown={isModalNoDataShown} hide={toggleNoDataModal} />
            <section className="energy-dashboard-employee__meter">
                <h2 className="energy-dashboard-employee__meter__title"><UserIcon /> Energy Balance</h2>
                <div className="meter-wrapper">
                    {/* <MeterProps className="meter-props" /> */}
                    <img src={MeterProps} className="meter-props" style={{ pointerEvents: "none" }}></img>
                    <MeterNeedle className="meter-needle" style={(!user) ? ({ ["--user-energy-meter" as any]: "-130deg" }) : ({ ["--user-energy-meter" as any]: ((user.energyBalance * 2.6) - 130) + "deg" })} />
                </div>
                <hr />
                <p className="energy-dashboard-employee__meter__percentage">{(!user) ? ("Loading...") : (Math.round(user.energyBalance) + "%")}</p>
                <p className="energy-dashboard-employee__meter__text"><ChargeIcon />
                    {(!user) ? ("we're gathering your info") :
                        (user.energyBalance <= 25) ? (
                            <>
                                Work in progress
                            </>
                        ) : (user.energyBalance <= 50) ? (
                            <>
                                Building momentum
                            </>
                        ) : (user.energyBalance <= 75) ? (
                            <>
                                Sustainability superhero in training
                            </>
                        ) : (user.energyBalance <= 100) ? (
                            <>
                                Well-being warrior
                            </>
                        ) : ("...")
                    }
                </p>
            </section>
            <section className="energy-dashboard-employee__metrics">
                <h2 className="energy-dashboard-employee__metrics__title">
                    <ReportIcon /> Your Wellbeing-Advices
                </h2>
                <ul className="energy-dashboard-employee__metrics__list">
                    {(!advice) ? ("Loading...") : (advice.length === 0) ? (<p style={{ textAlign: "center" }}>You don't have any advices. Have a great day!</p>) : (
                        advice.map((advice: any, index: number) => (
                            <Swiper
                                key={index}
                                modules={[Navigation]}
                                navigation={true}
                            >
                                {
                                    advice.advices.map((adviceSlider: any, index: number) => (
                                        <SwiperSlide key={index}>
                                            <div className="energy-dashboard-employee__metrics__item">
                                                <div className="energy-dashboard-employee__metrics__item__header">
                                                    <h3 className="energy-dashboard-employee__metrics__item__header__title" style={{ ["--metrics-color" as any]: adviceSlider.metricColor }}>{IconsAdvices[advice.categoryIcon] && React.createElement(IconsAdvices[advice.categoryIcon])} {advice.categoryName}</h3>
                                                    <div className="energy-dashboard-employee__metrics__item__header__right">
                                                        {(adviceSlider.quest?.totalDays > 0) ? (
                                                            <div className="energy-dashboard-employee__metrics__item__progression">
                                                                <div className="energy-dashboard-employee__metrics__item__progression__progress-bar" style={{ ["--metrics-color" as any]: adviceSlider.metricColor }}>
                                                                    <div className="energy-dashboard-employee__metrics__item__progression__progress-bar__progress" style={{ ["--metrics-color" as any]: adviceSlider.metricColor, ["--progress-width" as any]: 100 - (adviceSlider.quest.remainingDays /adviceSlider.quest.totalDays * 100) + "%" }}></div>
                                                                    <div className="energy-dashboard-employee__metrics__item__progression__progress-bar__thumb" style={{ ["--metrics-color" as any]: adviceSlider.metricColor, ["--progress-width" as any]: 100 - (adviceSlider.quest.remainingDays / adviceSlider.quest.totalDays * 100) + "%" }}></div>
                                                                </div>
                                                                <div className="energy-dashboard-employee__metrics__item__progression__details" style={{ ["--metrics-color" as any]: adviceSlider.metricColor }}>
                                                                    {adviceSlider.quest.remainingDays} days remaining
                                                                </div>
                                                            </div>) : ("")}
                                                        <GarbageIcon className='energy-dashboard-employee__metrics__item__garbage' onClick={() => { deleteQuest(adviceSlider) }} />
                                                    </div>
                                                </div>
                                                <div className="energy-dashboard-employee__metrics__item__body" >
                                                    <p className="energy-dashboard-employee__metrics__item__body__text">{adviceSlider.metricAdvice}</p>
                                                    <div className="energy-dashboard-employee__metrics__item__body__details">
                                                        <p>{IconsMetrics[adviceSlider.metricIcon] && React.createElement(IconsMetrics[adviceSlider.metricIcon])}<span>{adviceSlider.metricScore}{(adviceSlider.metricUnit === null) ? ("") : (adviceSlider.metricUnit)}</span> {adviceSlider.name}</p>

                                                        {(adviceSlider.quest?.totalDays === 0) ? (
                                                            <button style={{ ["--metrics-color" as any]: adviceSlider.metricColor }} onClick={() => { startQuest(adviceSlider) }}>Get Started</button>
                                                        ) : (adviceSlider.quest?.totalDays > 0) && (
                                                            (adviceSlider.quest.remainingDays === 0) ? (
                                                                <button style={{ ["--metrics-color" as any]: adviceSlider.metricColor }} onClick={() => { finishQuest(adviceSlider) }}>Finish</button>
                                                            ) : (
                                                                <div className="energy-dashboard-employee__metrics__item__progression--metric">
                                                                    <div className="energy-dashboard-employee__metrics__item__progression__progress-bar--metric" style={{ ["--metrics-color" as any]: adviceSlider.metricColor }}>
                                                                        <div className="energy-dashboard-employee__metrics__item__progression__progress-bar__progress--metric" style={{ ["--metrics-color" as any]: adviceSlider.metricColor, ["--progress-width" as any]: 100 - (adviceSlider.quest.remainingDays / adviceSlider.quest.totalDays * 100) + "%" }}></div>
                                                                        <div className="energy-dashboard-employee__metrics__item__progression__progress-bar__thumb--metric" style={{ ["--metrics-color" as any]: adviceSlider.metricColor, ["--progress-width" as any]: 100 - (adviceSlider.quest.remainingDays / adviceSlider.quest.totalDays * 100) + "%" }}></div>
                                                                        <TargetIcon className="energy-dashboard-employee__metrics__item__progression__progress-bar__thumb--target" />
                                                                    </div>
                                                                    <div className="energy-dashboard-employee__metrics__item__progression__details--metric" style={{ ["--metrics-color" as any]: adviceSlider.metricColor, ["--progress-width" as any]: 100 - (adviceSlider.quest.remainingDays / adviceSlider.quest.totalDays * 100) + "%" }}>
                                                                        <p>{adviceSlider.quest.old}</p>
                                                                        <p>{adviceSlider.quest.current}</p>
                                                                        <p>{adviceSlider.quest.target}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        ))
                    )}
                    <ModalQuestSurvey isShown={isModalQuestSurveyShown} hide={toggleQuestSurveyModal} advice={questAdvice} questSurvey={questSurvey} token={props.token} refreshAdvices={getAdvice} finish={questFinish} setQuestResults={setQuestResults} toggleQuestResultsModal={toggleQuestResultsModal} />
                    <ModalQuestResults isShown={isModalQuestResultsShown} hide={toggleQuestResultsModal} advice={questAdvice} questResults={questResults} />
                    <ModalQuestDelete isShown={isModalQuestDelteShown} hide={toggleQuestDeleteModal} advice={questAdvice} token={props.token} refreshAdvices={getAdvice} />
                </ul>
            </section>
            <section className="energy-dashboard-employee__graph">
                <div className="energy-dashboard-employee__graph__header">
                    <h2 className="energy-dashboard-employee__graph__header__title">Energy level over time</h2>
                    <Dropdown selectedValue={selectedValueDropdown} onDropdownChange={handleDropdownChange} />
                </div>

                {(!energyHistoryWeek) ? ("Loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.8}>
                        <ComposedChart data={energyHistoryWeek.averages}>
                            <XAxis dataKey="name" />
                            <YAxis width={25} />
                            <Tooltip content={customTooltip} />
                            <Legend content={renderLegend} />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="avgEnergyBalance" barSize={30} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            {/* <Line type="monotone" dataKey="globalAvgEnergyBalance" stroke="#01195D" label="globalAvgEnergyBalance" legendType="line" /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}
            </section>
            <section className="energy-dashboard-employee__activity-report">
                <div className="energy-dashboard-employee__activity-report__header">
                    <h2 className="energy-dashboard-employee__activity-report__header__title">Your <span>Activity report</span></h2>
                    <ul className="energy-dashboard-employee__activity-report__header__legend">
                        <li><MeetingsIcon /> {(activityReport) && (activityReport.report.find((obj: any) => obj.name === "Meetings")?.percent)}% Meetings</li>
                        <li><BreaksIcon /> {(activityReport) && (activityReport.report.find((obj: any) => obj.name === "Breaks")?.percent)}% Breaks</li>
                        <li><FocusIcon /> {(activityReport) && (activityReport.report.find((obj: any) => obj.name === "Focus")?.percent)}% Focus</li>
                        <li><PrivateIcon /> {(activityReport) && (activityReport.report.find((obj: any) => obj.name === "privateEvents")?.percent)}% Private Events</li>
                    </ul>
                </div>
                {
                    (!activityReport || !user) ? (
                        ""
                    ) : (
                        <ResponsiveContainer height={200} width={200}>
                            <PieChart  >
                                <Pie
                                    data={activityReport.report}
                                    dataKey="percent"
                                    paddingAngle={4}
                                    innerRadius={20}
                                    outerRadius={25}
                                    fill="#8884d8"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {activityReport.report.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={entry.color}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onClick={(e) => e.preventDefault()} />
                                    ))}
                                </Pie>
                                <Pie
                                    data={activityReport.report}
                                    dataKey="percent"
                                    innerRadius={25}
                                    outerRadius={70}
                                    paddingAngle={4}
                                    fill="white"
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {activityReport.report.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={"white"} onMouseDown={(e) => e.preventDefault()}
                                            onClick={(e) => e.preventDefault()} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    )
                }
            </section>
        </div >
    )
}

export default EnergyDashboard