import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from 'react'


import { ReactComponent as PositiveInteractionsIcon } from "../../assets/icons/icon-positive-interactions.svg";
import { ReactComponent as NegativeInteractionsIcon } from "../../assets/icons/icon-negative-interactions.svg";
import { ReactComponent as GraphWorkLife } from "../../assets/img/graph-work-life.svg";
import { ReactComponent as WorkIcon } from "../../assets/icons/icon-mindful-morning-work.svg";
import { ReactComponent as BreakIcon } from "../../assets/icons/icon-mindful-morning-break.svg";
import { ReactComponent as MeetingIcon } from "../../assets/icons/icon-mindful-morning-meeting.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/icon-mindful-morning-call.svg";
import { ReactComponent as DisconnectedIcon } from "../../assets/icons/icon-mindful-morning-disconnected.svg";
import { ReactComponent as MoveIcon } from "../../assets/icons/icon-move.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/icon-delete.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/advices/icon-target.svg";

import moment from 'moment';

import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import React from "react";
import { toast } from "react-toastify";
import IconsAdvices from "../general/IconsAdvices";
import IconsMetrics from "../general/IconsMetrics";
function ActivityReport(props: any) {
    const navigate = useNavigate();
    const [date, setDate] = useState<any>();
    const [mindfulMorningDate, setMindfulMorningDate] = useState<any>();
    const [timelineItems, setTimelineItems] = useState<any>([
        //Initial timeline items data
        { id: 1, availability: 'Loading..', startTime: "00:00", endTime: "00:00" },
        { id: 2, availability: 'Loading..', startTime: "00:00", endTime: "00:00" },
        { id: 3, availability: 'Loading..', startTime: "00:00", endTime: "00:00" },
        { id: 4, availability: 'Loading..', startTime: "00:00", endTime: "00:00" }
        // ...
    ]);
    const [advice, setAdvice] = useState<any>();
    const [resizingIndex, setResizingIndex] = useState<number | null>(null);
    const resizeItemRefs = useRef<Array<React.RefObject<HTMLLIElement>>>([]);

    useEffect(() => {
        resizeItemRefs.current = timelineItems.map(() => React.createRef());
        getDailyInterpretation();
    }, []);

    const handleMouseUp = () => {
        setResizingIndex(null);
    };

    const handleDelete = (itemId: number) => {
        console.log("Deleting item with id:", itemId);

        setTimelineItems((prevItems: any[]) => prevItems.filter(item => item.id !== itemId));
    };

    const getDailyInterpretation = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        try {
            const response = await fetch("https://mindy-api.azurewebsites.net/daily", options);
            const data = await response.json();
            console.log(response);
            console.log(data);
            setDate(data.date);
            const date = moment(data.date);
            const formattedDate = date.format("dddd Do [of] MMMM");
            const wordsArray = formattedDate.split(" ");
            const firstWord = wordsArray[0];
            const restOfSentence = formattedDate.slice(firstWord.length).trim();
            const epochTimeslots = data.timeslots.map((item: any) => ({
                ...item,
                startEpoch: timeToEpoch(data.date, item.startTime),
                endEpoch: timeToEpoch(data.date, item.endTime)
            }));
            setTimelineItems(epochTimeslots);
            setMindfulMorningDate([firstWord, restOfSentence]);
            setAdvice(data.advice)

            resizeItemRefs.current = data.timeslots.map(() => React.createRef());
        } catch (error) {
            console.log('Error fetching daily interpretation:', error);
        }
    }

    const updateDailyInterpretation = async () => {
        const epochTimeslots = timelineItems.map((item: any) => ({
            ...item,
            startEpoch: timeToEpoch(date, item.startTime),
            endEpoch: timeToEpoch(date, item.endTime)
        }));
        var options = {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
            body: JSON.stringify({ "timelineItems": epochTimeslots, "emotions": props.selectedEmotions, "score": props.selectedScore })
        }

        try {
            const response = await fetch("https://mindy-api.azurewebsites.net/daily", options);
            console.log(response);
            toast.success("Mindful Morning finished !", {
                position: toast.POSITION.TOP_RIGHT
            });
            navigate("/");
        } catch (error) {
            console.log('Error updating ', error);
            toast.error("Mindful Morning ERROR !", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    function timeToEpoch(dateString: any, time: any) {
        console.log("getting epoch for date: " + dateString + " and time: " + time);
        console.log("getting epoch for date SPLIT: " + dateString.split('T')[0]);
        const dateTime = new Date(dateString.split('T')[0] + 'T' + time);
        return dateTime.getTime();
    }

    return (
        <div className="activity-report" onMouseUp={handleMouseUp}>
            <h2 className="activity-report__title">{(mindfulMorningDate) ? (<><span>{mindfulMorningDate[0]}</span> {mindfulMorningDate[1]}</>) : ("loading...")}</h2>
            <div className="activity-report__container">
                <ol className="activity-report-timeline">
                    {timelineItems.map((item: any, index: any) => {

                        return (
                            <li
                                ref={resizeItemRefs.current[index]}
                                key={item.id}
                                className="activity-report-timeline-item"
                            >
                                <span className={`activity-report-timeline-item-icon ${item.availability === 'Break' || item.availability === "Recuperation" || item.availability === "Disconnected" ? 'filled-icon' : 'faded-icon'}`}>
                                    {
                                        (item.availability === "Meeting") ? (<MeetingIcon />) :
                                            (item.availability === "Call") ? (<CallIcon />) :
                                                (item.availability === "Work") ? (<WorkIcon />) :
                                                    (item.availability === "Break") ? (<BreakIcon />) :
                                                        (item.availability === "Recuperation") ? (<BreakIcon />) :
                                                            (item.availability === "Disconnected") ? (<DisconnectedIcon />) :
                                                                (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                                                        <path fill="none" d="M0 0h24v24H0z" />
                                                                        <path fill="currentColor"
                                                                            d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
                                                                    </svg>
                                                                )
                                    }
                                </span>
                                <div className="activity-report-timeline-item-content">
                                    <p className={`activity-report-timeline-item-content__time ${item.availability === 'Break' || item.availability === "Recuperation" || item.availability === "Disconnected" ? 'clr-hr' : ''}`}>{item.startTime}-{item.endTime}</p>
                                    <h3 className="activity-report-timeline-item-content__title">{item.availability}</h3>
                                </div>
                                <span className="activity-report-timeline-item-actions">
                                    {/* <MoveIcon className="move-icon"
                                        // onMouseDown={(event) => handleMouseDown(event, index)}
                                        // onMouseUp={handleMouseUp}
                                        // onMouseMove={(event) => handleMouseMove(event, index)} 
                                    /> */}
                                    <DeleteIcon
                                        className="delete-icon"
                                        onClick={() => handleDelete(item.id)}
                                    />
                                </span>
                            </li>
                        );
                    })}
                </ol>
                {advice && (
                    <div className="activity-report-quest">
                        <div className="activity-report-quest__header">
                            <h3 className="activity-report-quest__header__title" style={{ ["--metrics-color" as any]: advice.advices[0].metricColor }}>{IconsAdvices[advice.categoryIcon] && React.createElement(IconsAdvices[advice.categoryIcon])} {advice.categoryName} <span> - Quest</span></h3>
                            <div className="activity-report-quest__header__right">
                                <div className="activity-report-quest__progression">
                                    <div className="activity-report-quest__progression__details" style={{ ["--metrics-color" as any]: advice.advices[0].metricColor }}>
                                        {advice.advices[0].quest.remainingDays} days remaining
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="activity-report-quest__body" >
                            <p className="activity-report-quest__body__text">{advice.advices[0].metricAdvice}</p>
                            <div className="activity-report-quest__body__details">
                                <p>{IconsMetrics[advice.advices[0].metricIcon] && React.createElement(IconsMetrics[advice.advices[0].metricIcon])}<span>{advice.advices[0].metricScore}{(advice.advices[0].metricUnit === null) ? ("") : (advice.advices[0].metricUnit)}</span> {advice.advices[0].name}</p>
                                <div className="activity-report-quest__progression--metric">
                                    <div className="activity-report-quest__progression__progress-bar--metric" style={{ ["--metrics-color" as any]: advice.advices[0].metricColor }}>
                                        <div className="activity-report-quest__progression__progress-bar__progress--metric" style={{ ["--metrics-color" as any]: advice.advices[0].metricColor, ["--progress-width" as any]: 100 - (advice.advices[0].quest.remainingDays / advice.advices[0].quest.totalDays * 100) + "%" }}></div>
                                        <div className="activity-report-quest__progression__progress-bar__thumb--metric" style={{ ["--metrics-color" as any]: advice.advices[0].metricColor, ["--progress-width" as any]: 100 - (advice.advices[0].quest.remainingDays / advice.advices[0].quest.totalDays * 100) + "%" }}></div>
                                        <TargetIcon className="activity-report-quest__progression__progress-bar__thumb--target" />
                                    </div>
                                    <div className="activity-report-quest__progression__details--metric" style={{ ["--metrics-color" as any]: advice.metricColor, ["--progress-width" as any]: 100 - (advice.advices[0].quest.remainingDays / advice.advices[0].quest.totalDays * 100) + "%" }}>
                                        <p>{advice.advices[0].quest.old}</p>
                                        <p>{advice.advices[0].quest.current}</p>
                                        <p>{advice.advices[0].quest.target}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* <div className="activity-report-worklife">
                    <h3 className="activity-report-worklife__title">Work-Life Balance</h3>
                    <GraphWorkLife className="activity-report-worklife__graph" />
                    <div>
                        <p></p>
                        <p></p>
                    </div>
                    <hr />
                    <p className="activity-report-worklife__text">Your balance is really good! Keep it up!</p>
                    <div className="activity-report-worklife__progression">
                        <div className="activity-report-worklife__progression__progress-bar">
                            <div className="activity-report-worklife__progression__progress-bar__thumb" style={{ ["--metrics-color" as any]: "#7A71FD" }}></div>
                            <div className="activity-report-worklife__progression__progress-bar__progress" style={{ ['--progress-width' as any]: "40%", ["--metrics-color" as any]: "#7A71FD" }}></div>
                        </div>
                        <div className="activity-report-worklife__progression__details">
                            <p>overworking</p>
                            <p>disconnected</p>
                        </div>
                    </div>
                </div> */}
                <div className="activity-report__btn-group">
                    {/* <button className="btn-secondary">Back</button> */}
                    <button className="btn-primary" onClick={updateDailyInterpretation}>Finish</button>
                </div>
            </div>

        </div>
    )
}

export default ActivityReport
