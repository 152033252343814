import { useState, useEffect } from 'react'
import { ReactComponent as OrganisationIcon } from '../../assets/icons/icon-organisation.svg';
import { ReactComponent as ChargeIcon } from "../../assets/icons/icon-charge.svg"
import { ReactComponent as RepeatIcon } from "../../assets/icons/icon-repeat.svg"
import { ReactComponent as DangerIcon } from "../../assets/icons/icon-danger.svg"
import { ReactComponent as BatteryPlusIcon } from "../../assets/icons/icon-battery-plus.svg"
import { ReactComponent as ReportIcon } from "../../assets/icons/icon-report.svg";
import MeterProps from "../../assets/img/meter-props.png";
import { ReactComponent as MeterNeedle } from "../../assets/img/meter-needle.svg";

import { ReactComponent as MeetingsIcon } from "../../assets/icons/icon-meetings.svg";
import { ReactComponent as BreaksIcon } from "../../assets/icons/icon-breaks.svg";
import { ReactComponent as FocusIcon } from "../../assets/icons/icon-focus.svg";
import { ReactComponent as PrivateIcon } from "../../assets/icons/icon-private.svg";

import { ReactComponent as LineIcon } from "../../assets/icons/icon-line.svg";

import { Area, Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import IconsMetrics from "../general/IconsMetrics";
import React from "react";
import IconsAdvices from "../general/IconsAdvices";
import { ModalNoData } from '../general/ModalNoData';
import { useModal } from '../general/useModal';
import { ModalNotEnoughEmployees } from './ModalNotEnoughEmployees';

function EnergyDashboard(props: any) {
    const [energyHistory, setEnergyHistory] = useState<any>();
    const [energyHistoryWeek, setEnergyHistoryWeek] = useState<any>();
    const [energyStats, setEnergyStats] = useState<any>();
    const [engagement, setEngagement] = useState<any>();
    const [advice, setAdvice] = useState<any>();
    const [activityReport, setActivityReport] = useState<any>();
    const { isShown: isModalNoDataShown, toggle: toggleNoDataModal } = useModal();


    useEffect(() => {
        const queryString = `?departments=${props.filters.departments}&entities=${props.filters.entities}&jobTitles=${props.filters.jobTitles}&countries=${props.filters.countries}`;
        getEnergyBalance(queryString);
        getEnergyStats(queryString);
        getEnergyBalanceWeek(props.filters.view, queryString);
        getAdvice(queryString);
        getActivityReport(queryString);
    }, [props.filters])

    const getEnergyBalance = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/energyHistory" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);

        setEnergyHistory(data);


    }

    const getEnergyStats = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/energyStats" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setEnergyStats(data);
    }

    const getAdvice = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/advice" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        console.log(data[0]);
        setAdvice(data);
    }

    const getEnergyBalanceWeek = async (view: string, queryString: string) => {


        console.log(`Getting energy balance for view: ${view}`);
        const apiUrl = `https://mindy-api.azurewebsites.net/energyHistory/${view}`;
        const apiUrlFilter = `https://mindy-api.azurewebsites.net/energyHistory/${view}${queryString}`;
        const fetchEnergyData = async (url: any, options: any) => {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(response);
            console.log(data);
            return data;
        };

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            },
        };

        const data = await fetchEnergyData(apiUrl, options)

        if (props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) {
            const filteredData = await fetchEnergyData(apiUrlFilter, options);

            for (let i = 0; i < filteredData.averages.length; i++) {
                data.averages[i].avgEnergyBalanceFilter = filteredData.averages[i].avgEnergyBalance;
            }

            console.log(`Data after adding filter: ${JSON.stringify(filteredData)}`);

        }
        setEnergyHistoryWeek(data);

    }

    const getActivityReport = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/activityReport" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        console.log(data[0]);
        setActivityReport(data);

    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = (props: any) => {
        const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
        const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
        const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

        return (
            <svg x={x - 10} y={y - 10} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                {(props.payload.payload.percent === 0) ? (
                    ""
                ) : (props.payload.payload.name == "Breaks") ? (
                    <BreaksIcon height={14} width={14} />
                ) : (props.payload.payload.name == "privateEvents") ? (
                    <PrivateIcon height={15} width={12} />
                ) : (props.payload.payload.name == "Focus") ? (
                    <FocusIcon height={15} width={15} />
                ) : (props.payload.payload.name === "Meetings") ? (
                    <MeetingsIcon height={16} width={18} />
                ) : ("")}
                {/* {(props.payload.payload.percent === 0) ? (
                    ""
                ) : (
                    // <text x="5.5" y="45" dominantBaseline="central" fill={props.payload.payload.color}>{props.payload.payload.percentage}</text>
                    <path x="" d="M15.8297 26.3948C13.9972 26.6069 12.1401 26.4035 10.3987 25.8002C8.65721 25.1968 7.07706 24.2093 5.77764 22.9121C4.3073 21.4557 3.23427 19.6535 2.65905 17.6743C2.08384 15.6951 2.02528 13.6036 2.48885 11.5959C2.95242 9.58814 3.92296 7.72979 5.30954 6.19492C6.69612 4.66005 8.45337 3.49889 10.4167 2.82022C12.38 2.14155 14.4851 1.96757 16.5348 2.31458C18.5845 2.66159 20.5118 3.51824 22.136 4.80428C23.7603 6.09032 25.0283 7.76366 25.8215 9.66754C26.6146 11.5714 26.9068 13.6436 26.6707 15.6898M14.4647 7.5482V14.3081L17.1947 17.0121M21.2898 21.068V27.8279M26.7499 21.068V27.8279" stroke="#C174FF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                )} */}
            </svg>
        );
    };

    const customTooltip = (props: any) => {
        const { payload } = props;
        if (payload && payload.length) {
            console.log("payload tooltip :" + JSON.stringify(payload));

            return (
                <div style={{ backgroundColor: 'white', padding: 8 }}>
                    <p style={{ color: "#01195D" }}>{payload[0].payload.name}: {payload[0].payload.avgEnergyBalance}</p>
                    {(payload[0].payload.avgEnergyBalanceFilter !== undefined) ? (<p style={{ color: "#086DFD" }}>{payload[0].payload.name + " Segment"}: {payload[0].payload.avgEnergyBalanceFilter}</p>) : ("")}
                    {/* <p>Your Average: {payload[0].payload.globalAvgEnergyBalance}</p> */}
                </div>
            );
        }
        return null;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        console.log("payload legend :" + JSON.stringify(payload));

        return (
            <ul style={{ display: "flex", justifyContent: "center" }}>
                {
                    payload.map((entry: any, index: any) => {
                        console.log("Entry legend:" + JSON.stringify(entry));

                        if (entry.payload.legendType !== 'none') {
                            return (
                                <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", fontSize: 8, color: '#01195D', fontWeight: 700, gap: 8 }}><LineIcon />YOUR AVERAGE</li>
                            );
                        }
                    })
                }
            </ul>
        );
    }

    return (
        <div className={`energy-dashboard ${energyStats && energyStats.inFirstWeek ? 'blur' : ''}`} >
            <ModalNotEnoughEmployees isShown={energyStats && energyStats.inFirstWeek ? true : false} hide={toggleNoDataModal} />
            <section className="energy-dashboard__meter">
                <h2 className="energy-dashboard__meter__title"><OrganisationIcon />Mindthriven's Energy balance</h2>
                <div className="meter-wrapper">
                    {/* <MeterProps className="meter-props" /> */}
                    <img src={MeterProps} className="meter-props" style={{ pointerEvents: "none" }}></img>
                    <MeterNeedle className="meter-needle" style={(!energyHistory) ? ({ ["--user-energy-meter" as any]: "-130deg" }) : ({ ["--user-energy-meter" as any]: ((energyHistory.averages * 2.6) - 130) + "deg" })} />
                </div>
                <p className="energy-dashboard__meter__percentage">{(!energyHistory) ? ("Loading...") : (Math.round(energyHistory.averages) + "%")}</p>
                <p className="energy-dashboard__meter__text"><ChargeIcon /> working sustainably</p>
                <dl className="energy-dashboard__meter__stats">
                    <li className="energy-dashboard__meter__stats__item">
                        <h3 className="energy-dashboard__meter__stats__item__title" ><RepeatIcon />{(!energyStats) ? ("...") : (energyStats.totalAtRisk)}</h3>
                        <p className="energy-dashboard__meter__stats__item__description">Total people at risk </p>
                    </li>
                    <li className="energy-dashboard__meter__stats__item">
                        <h3 className="energy-dashboard__meter__stats__item__title" style={{ color: '#E23B4F' }}><DangerIcon />{(!energyStats) ? ("...") : (energyStats.currentAtRisk)}</h3>
                        <p className="energy-dashboard__meter__stats__item__description">People at risk now.</p>
                    </li>
                    <li className="energy-dashboard__meter__stats__item">
                        <h3 className="energy-dashboard__meter__stats__item__title" style={{ color: '#086DFD' }}><BatteryPlusIcon />{(!energyStats) ? ("...") : (energyStats.recoveredSince)}</h3>
                        <p className="energy-dashboard__meter__stats__item__description">Recovered since Mindy</p>
                    </li>
                </dl>
            </section>
            <section className="energy-dashboard__metrics">
                <h2 className="energy-dashboard__metrics__title">
                    <ReportIcon />Company's Insights
                </h2>
                <ul className="energy-dashboard__metrics__list">
                    {(!advice) ? ("Loading...") : (advice.length === 0) ? (<p style={{ textAlign: "center" }}>Your company doesn't have any advices at the moment. Have a great day!</p>) : (
                        advice.map((advice: any) => (
                            <div className="energy-dashboard__metrics__item">
                                <div className="energy-dashboard__metrics__item__body" >
                                    {/* {advice.advices.map((adviceItem: any, index:any) => {})} */}
                                    <h3 className="energy-dashboard__metrics__item__body__title" style={{ ["--metrics-color" as any]: advice.categoryColor }}>{IconsAdvices[advice.categoryIcon] && React.createElement(IconsAdvices[advice.categoryIcon])} {advice.categoryName}</h3>
                                    <div className="energy-dashboard-employee__metrics__item__progression__details--metric" style={{ ["--metrics-color" as any]: advice.categoryColor, ["--progress-width" as any]: (advice.activeQuests / advice.totalUsers * 100) + "%" }}>
                                        <p></p>
                                        <p>{advice.activeQuests}</p>
                                        <p>{advice.totalUsers}</p>
                                    </div>
                                    <div className="energy-dashboard-employee__metrics__item__progression">

                                        <div className="energy-dashboard__metrics__item__progression__progress-bar" style={{ ["--metrics-color" as any]: advice.categoryColor, width:"100%" }}>
                                            <div className="energy-dashboard__metrics__item__progression__progress-bar__progress" style={{ ["--metrics-color" as any]: advice.categoryColor, ["--progress-width" as any]: (advice.activeQuests / advice.totalUsers * 100) + "%" }}></div>
                                            <div className="energy-dashboard-employee__metrics__item__progression__progress-bar__thumb" style={{ ["--metrics-color" as any]: advice.categoryColor, ["--progress-width" as any]: (advice.activeQuests / advice.totalUsers * 100) + "%" }}></div>
                                        </div>
                                    </div>
                                    <div className="energy-dashboard-employee__metrics__item__progression__details" style={{ ["--metrics-color" as any]: advice.categoryColor, ["--progress-width" as any]: (advice.activeQuests / advice.totalUsers * 100) + "%" }}>
                                        <p>Active interventions</p>
                                        <p>Total Employees</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </ul>

            </section>
            <section className="energy-dashboard__graph">
                <div className="energy-dashboard__graph__header">
                    <h2 className="energy-dashboard__graph__header__title">Energy level over time</h2>
                </div>

                {(!energyHistoryWeek) ? ("Loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.8}>
                        <ComposedChart data={energyHistoryWeek.averages} >
                            <XAxis dataKey="name" />
                            <YAxis width={25} />
                            <Tooltip content={customTooltip} />
                            <Legend content={renderLegend} />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="avgEnergyBalance" barSize={30} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            {(props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) ? (
                                <Bar dataKey="avgEnergyBalanceFilter" barSize={30} fill="#086DFD" legendType="none" radius={[4, 4, 0, 0]} />
                            ) : ("")}

                            {/* <Line type="monotone" dataKey="globalAvgEnergyBalance" stroke="#01195D" label="globalAvgEnergyBalance" legendType="line" /> */}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}

            </section>
            <section className="energy-dashboard__activity-report">
                <div className="energy-dashboard__activity-report__header">
                    <h2 className="energy-dashboard__activity-report__header__title">Mindthriven's <span>Activity report</span></h2>
                    <ul className="energy-dashboard-employee__activity-report__header__legend">
                        <li><MeetingsIcon /> {(!activityReport) ? ("...") : (activityReport.report.find((obj: any) => obj.name === "Meetings")?.percent)}% Meetings</li>
                        <li><BreaksIcon /> {(!activityReport) ? ("...") : (activityReport.report.find((obj: any) => obj.name === "Breaks")?.percent)}% Breaks</li>
                        <li><FocusIcon /> {(!activityReport) ? ("...") : (activityReport.report.find((obj: any) => obj.name === "Focus")?.percent)}% Focus</li>
                        <li><PrivateIcon /> {(!activityReport) ? ("...") : (activityReport.report.find((obj: any) => obj.name === "privateEvents")?.percent)}% Private Events</li>
                    </ul>
                </div>
                {
                    (!activityReport) ? (
                        ""
                    ) : (
                        <ResponsiveContainer height={200} width={200}>
                            <PieChart  >
                                <Pie
                                    data={activityReport.report}
                                    dataKey="percent"
                                    paddingAngle={4}
                                    innerRadius={20}
                                    outerRadius={25}
                                    fill="#8884d8"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {activityReport.report.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} onMouseDown={(e) => e.preventDefault()}
                                            onClick={(e) => e.preventDefault()} />
                                    ))}
                                </Pie>
                                <Pie
                                    data={activityReport.report}
                                    dataKey="percent"
                                    innerRadius={25}
                                    outerRadius={70}
                                    paddingAngle={4}
                                    fill="white"
                                    label={renderCustomizedLabel}
                                    labelLine={false}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    {activityReport.report.map((entry: any, index: any) => (
                                        <Cell key={`cell-${index}`} fill={"white"} onMouseDown={(e) => e.preventDefault()}
                                            onClick={(e) => e.preventDefault()} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    )
                }
            </section>
        </div>
    )
}

export default EnergyDashboard