import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";


// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import { SurveyListItem } from './SurveyListItem';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    surveys: any;
    token: string;
    userId: number;
}

export const ModalSurveyList: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    surveys,
    token,
    userId
}) => {

    const modalSurveyList = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-survey-list">
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        <div className='modal-survey-list__header'>
                            <h2 className='modal-survey-list__header__title'>All Surveys</h2>
                        </div>
                        <div className='modal-survey-list__body'>
                            <ul className="survey-list">
                                {(!surveys) ? ("Loading...") : (surveys.length === 0) ? (<p style={{ alignSelf: "center" }}>You havent made any surveys yet.</p>) : (
                                    surveys.map((survey: any, index: any) => {
                                        return <SurveyListItem key={index} data={survey} token={token} userId={userId}/>
                                    })
                                )}
                            </ul>
                        </div>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalSurveyList, rootContainer) : null;
};