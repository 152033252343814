import { useEffect, useRef, useState } from 'react'

import { ReactComponent as InfoIcon } from '../../assets/icons/icon-info.svg';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { Tooltip as MetricTooltip } from "react-tooltip";
import IconsMetrics from "../general/IconsMetrics";
import { ReactComponent as LineIcon } from "../../assets/icons/icon-line.svg";
import { ReactComponent as SegmentLineIcon } from "../../assets/icons/icon-line-segment.svg";
import React from "react";

function WorkBehaviour(props: any) {

    // const queryStringRef = useRef<string>('');
    // const viewRef = useRef<string>('');
    const [activeTab, setActiveTab] = useState(0);
    const [workPatterns, setWorkPatterns] = useState<any>();
    const [workPatternHistory, setWorkPatternHistory] = useState<any>();
    //const [activeWorkPattern, setActiveWorkPattern] = useState(0);
    const [activeMetric, setActiveMetric] = useState(0);
    console.log("active workpattern" + activeMetric);

    useEffect(() => {
        const queryString = `?departments=${props.filters.departments}&entities=${props.filters.entities}&jobTitles=${props.filters.jobTitles}&countries=${props.filters.countries}`;
        // queryStringRef.current = queryString;
        // viewRef.current = props.filters.view;
        getWorkPattern(queryString);
        getWorkPatternHistory(activeMetric, props.filters.view, queryString);
    }, [props.filters, activeTab, activeMetric])

    const getWorkPattern = async (queryString: string) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/workPattern"+queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setWorkPatterns(data);
    }

    const getWorkPatternHistory = async (activeMetricIndex: number, view: string, queryString: string) => {
        // var options = {
        //     method: 'GET',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + props.token,
        //     },
        // }
        // const response = await fetch("https://mindy-api.azurewebsites.net/GraphQL/workPattern/week", options);
        // const responseData = await response.json();

        // let averages;

        // //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
        // //console.log("active metric:" + JSON.stringify(activeMetric));

        // averages = responseData.averages.map((item: any) => ({
        //     name: item.name,
        //     average: item.metrics[activeTab].metrics[activeMetricIndex].metricScore,
        //     globalAvg: item.metrics[activeTab].metrics[activeMetricIndex].globalAvg
        // }));

        // console.log("averages: " + JSON.stringify(averages));
        // setWorkPatternHistory(averages);


        console.log(`Getting workbehaviour balance for view: ${view}`);
        const apiUrl = `https://mindy-api.azurewebsites.net/workPattern/${view}`;
        const apiUrlFilter = `https://mindy-api.azurewebsites.net/workPattern/${view}${queryString}`;
        const fetchWorkbehaviourData = async (url: any, options: any) => {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(response);
            console.log(data);
            return data;
        };

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${props.token}`,
            },
        };

        const data = await fetchWorkbehaviourData(apiUrl, options)
        var averages = data.averages.map((item: any) => ({
            name: item.name,
            average: item.metrics[activeTab].metrics[activeMetricIndex].metricScore,
            globalAvg: item.metrics[activeTab].metrics[activeMetricIndex].globalAvg
        }));

        console.log("averages: " + JSON.stringify(averages));

        if (props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) {
            const filteredData = await fetchWorkbehaviourData(apiUrlFilter, options);

            for (let i = 0; i < filteredData.averages.length; i++) {
                averages[i].averageFilter = filteredData.averages[i].metrics[activeTab].metrics[activeMetricIndex].metricScore;
                averages[i].globalAvgFilter = filteredData.averages[i].metrics[activeTab].metrics[activeMetricIndex].globalAvg;
            }

            console.log(`Data after adding filter: ${JSON.stringify(averages)}`);

        }
        setWorkPatternHistory(averages);
    }

    const handleClickTab = (index: number) => {
        setActiveTab(index);
        setActiveMetric(0);
    };

    const handleClickMetric = (index: number) => {
        console.log("clicked metric: " + index);
        setActiveMetric(index);
        //getWorkPatternHistory(index, viewRef.current, queryStringRef.current);
    };

    const customTooltip = (props: any) => {
        const { payload } = props;
        if (payload && payload.length) {
            console.log("payload tooltip :" + JSON.stringify(payload));

            return (
                <div style={{ backgroundColor: 'white', padding: 8 }}>
                    <p style={{color:"#01195D"}}>{payload[0].payload.name}: {payload[0].payload.average}</p>
                    <p style={{color:"#01195D"}}>Company Average: {payload[0].payload.globalAvg}</p>
                    {(payload[0].payload.averageFilter !== undefined && payload[0].payload.globalAvgFilter !== undefined) ? (
                        <>
                            <p style={{color:"#086DFD"}}>{payload[0].payload.name + " Segment"}: {payload[0].payload.averageFilter}</p>
                            <p style={{color:"#086DFD"}}>Segment Average: {payload[0].payload.globalAvgFilter}</p>
                        </>
                    ) : ("")}
                </div>
            );
        }
        return null;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        console.log("payload legend :" + JSON.stringify(payload));

        return (
            <ul style={{ display: "flex", justifyContent: "center", gap: 32 }}>
                {
                    payload.map((entry: any, index: any) => {
                        console.log("Entry legend:" + JSON.stringify(entry));

                        if (entry.payload.legendType !== 'none' && entry.payload.label === "averageSegment") {
                            return (
                                <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", fontSize: 8, color: '#086dfd', fontWeight: 700, gap: 8 }}><SegmentLineIcon /> Segment AVERAGE</li>
                            );
                        }else if(entry.payload.legendType !== 'none'){
                            return (
                                <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", fontSize: 8, color: '#01195D', fontWeight: 700, gap: 8 }}><LineIcon /> Company AVERAGE</li>
                            );
                        }

                    })
                }
            </ul>
        );
    }

    return (
        <div className="work-behaviour">
            <section className="work-behaviour__graph">
                <div className="work-behaviour__graph__header">
                    <h2 className="work-behaviour__graph__header__title">Mindthriven's <span>work behaviour</span></h2>
                </div>
                {(!workPatternHistory) ? ("loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.6}>
                        <ComposedChart data={workPatternHistory} >
                            <XAxis dataKey="name" />
                            <YAxis width={25}/>
                            <Tooltip content={customTooltip} />
                            <Legend content={renderLegend} />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="average" barSize={20} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            <Line type="monotone" dataKey="globalAvg" stroke="#01195D" label="average" legendType="line" />
                            {(props.filters.departments.length > 0 || props.filters.jobTitles.length > 0) ? (
                                <>
                                    <Bar dataKey="averageFilter" barSize={20} fill="#086DFD" legendType="none" radius={[4, 4, 0, 0]} />
                                    <Line type="monotone" dataKey="globalAvgFilter" stroke="#086DFD" label="averageSegment" legendType="line" />
                                </>

                            ) : ("")}
                        </ComposedChart>
                    </ResponsiveContainer>
                )}

            </section>
            <section className="work-behaviour__work-patterns">
                <h2 className="work-behaviour__work-patterns__title">Work Patterns</h2>
                <ol className="work-behaviour__work-patterns__tabs">
                    {(!workPatterns) ? (
                        <li className="work-behaviour__work-patterns__tabs__title active">Loading...</li>
                    ) : (
                        workPatterns.map((category: any, index: number) => (
                            <li
                                key={index}
                                className={`work-behaviour__work-patterns__tabs__title ${activeTab === index ? "active" : ""
                                    }`}
                                onClick={() => handleClickTab(index)}
                            >
                                {category.categoryName}
                            </li>
                        ))
                    )}
                </ol>
                {(!workPatterns) ? ("Loading...") : (
                    <dl className="work-behaviour__work-patterns__tab-content">
                        {workPatterns[activeTab].metrics.map((metric: any, index: number) => (
                            <li
                                key={index}
                                className={`work-behaviour__work-patterns__tab-content__item ${activeMetric === index ? "active" : ""
                                    }`}
                                onClick={() => handleClickMetric(index)}
                            >
                                {IconsMetrics[metric.metricIcon] && React.createElement(IconsMetrics[metric.metricIcon])}
                                <p>{metric.name}</p>
                                <span>{metric.metricScore}{metric.metricUnit}</span>
                                <InfoIcon data-tooltip-id={"tooltip-metric-" + index} onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()} />
                                <MetricTooltip id={"tooltip-metric-" + index} place="top-end" content={metric.tooltip} />
                            </li>
                        ))}
                    </dl>
                )}

            </section>
        </div>
    )
}

export default WorkBehaviour