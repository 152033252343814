import { Link } from "react-router-dom";

import { ReactComponent as IconGraph } from "../../assets/icons/icon_graph.svg";
import { ReactComponent as IconCalendar } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as IconTextBubble } from "../../assets/icons/icon_text_bubble.svg";
import { ReactComponent as IconCheck } from "../../assets/icons/icon_check_small.svg";

import { useModal } from "../general/useModal";
import { Modal } from "./ModalHRDetailsSurvey";

export function SurveyListItem(props:any) {

  const { isShown, toggle } = useModal();

  return (
    <li className="survey-list-item"> 
      <p className="date | txt-primary body-1">{props.data.startDate}</p>
      <p className="name body-2">{props.data.surveyTitle}</p>
      <p className={"status | txt-primary body-3 " + (props.data.status === 'completed' && " completed")}>
        <span>
          { props.data.status === 'scheduled' && <IconCalendar />} 
          { props.data.status === 'inprogress' && <IconTextBubble />}
          { props.data.status === 'completed' && <IconCheck />}
        </span>
        {props.data.status}
      </p>
      {props.data.status !== 'scheduled' && 
        <button className="btn--show-survey | btn btn--icon" onClick={toggle}><IconGraph /></button>
      }
      <Modal isShown={isShown} hide={toggle} token={props.token} hrSurveyId={props.data.hrSurveyId}/>
    </li>
  );
}