import { toast } from "react-toastify";
import IconsTopics from "../general/IconsTopics";
import { ReactComponent as IconGood } from '../../assets/icons/topics/icon-good.svg';
import { ReactComponent as IconMid } from '../../assets/icons/topics/icon-mid.svg';
import { ReactComponent as IconBad } from '../../assets/icons/topics/icon-bad.svg';
import React from "react";

export function MetricCategory(props: any) {

  const handleCheckboxChange = async (event: any) => {
    var options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + props.token,
      },
      body: JSON.stringify({ "topicId": props.data.id, "enabled": event.target.checked })
    }
    try {
      const response = await fetch("https://mindy-api.azurewebsites.net/topics", options);
      console.log(response);

      toast.success(event.target.checked ? "Topic turned ON" : "Topic turned OFF", {
        position: toast.POSITION.TOP_RIGHT
      });

    } catch (error) {
      console.log('Error fetching daily interpretation:', error);
      toast.error("Failed updating topic", {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <div className="metric-category">
      <div className="metric-category__container">
        <h3 className={"metric-category__title "} >{props.data.name}</h3>
        <div className={"metric-category__icon "}>
          {IconsTopics[props.data.icon] && React.createElement(IconsTopics[props.data.icon])}
        </div>
        <p className="metric-category__points">

          {
            props.data.score === -1
              ? "n.a."
              : props.data.maxIsGood
                ? props.data.score >= 0 && props.data.score <= 2
                  ? <IconGood />
                  : props.data.score > 2 && props.data.score <= 3
                    ? <IconMid />
                    : props.data.score > 3 && props.data.score <= 5
                      ? <IconBad />
                      : "error"
                : props.data.score >= 0 && props.data.score <= 2
                  ? <IconBad />
                  : props.data.score > 2 && props.data.score <= 3
                    ? <IconMid />
                    : props.data.score > 3 && props.data.score <= 5
                      ? <IconGood />
                      : "error"
          }

        </p>
        <label className="toggle" htmlFor={"metric-category-check" + props.data.id}>
          <input type="checkbox" className="toggle__input" id={"metric-category-check" + props.data.id} defaultChecked={props.data.enabled} onChange={handleCheckboxChange} />
          <span className="toggle-track">
            <span className="toggle-indicator">
              <span className="checkMark">
                <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                  <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                </svg>
              </span>
            </span>
          </span>
        </label>
        <small className="metric-category__date-edited">{props.data.dateEdited}</small>
      </div>

    </div>
  );
}