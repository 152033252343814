import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconBackgroundProfile } from "../../assets/img/background-img-profile.svg";

import { toast } from 'react-toastify';

import {
    Wrapper,
    StyledModal,
    Backdrop,
} from '../general/modal.style';
import { ReactComponent as MMIcon } from "../../assets/icons/icon-mm.svg";

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
}

export const ModalNoData: FunctionComponent<ModalProps> = ({
    isShown,
    hide,

}) => {
    
    const modalNoData = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-no-data">
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        <MMIcon style={{ marginBottom: 20 }} />
                        <h2 style={{textAlign:'center'}}>I'm getting to know you...</h2>
                        <p style={{textAlign:'center'}}>Your energy dashboard will light up one week after your onboarding. Feel free to check out the other pages and dig deeper into your well-being and work patterns. Exciting updates ahead!</p>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalNoData, rootContainer) : null;
};