import { ReactComponent as IconGraph } from "../../assets/icons/icon_graph.svg";
import { ReactComponent as IconArrow } from "../../assets/icons/icon_arrow_right_small.svg";
import { ReactComponent as IconCalendar } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as IconTextBubble } from "../../assets/icons/icon_text_bubble.svg";
import { ReactComponent as IconCheck } from "../../assets/icons/icon_check_small.svg";

import { useModal } from "../general/useModal";
import { Modal } from "./ModalHRDetailsSurvey";
import { useNavigate } from "react-router-dom";

export function SurveyListItem(props:any) {
  const navigate = useNavigate();
  const { isShown, toggle } = useModal();

  function goToSurvey(){
    navigate("/survey/" + props.data.id);
  }

  return (
    <li className="survey-list-item"> 
      <p className="date | txt-primary body-1">{props.data.startDate}</p>
      <p className="name body-2">{props.data.surveyTitle}</p>
      <p className={"status | txt-primary body-3 " + (props.data.status === 'completed' && " completed")}>
        <span>
          { props.data.status === 'scheduled' && <IconCalendar />} 
          { props.data.status === 'inprogress' && <IconTextBubble />}
          { props.data.status === 'completed' && <IconCheck />}
        </span>
        {props.data.status}
      </p>
      {(props.data.status === 'inprogress')?(
        <button className="btn--show-survey | btn btn--icon" onClick={goToSurvey}><IconArrow /></button>
      ):(props.data.status !== 'scheduled')&&(<button className="btn--show-survey | btn btn--icon" onClick={toggle}><IconGraph /></button>)}
      <Modal isShown={isShown} hide={toggle} headerText={""} token={props.token} hrSurveyId={props.data.hrSurveyId} userId={props.userId}/>
    </li>
  );
}