import { useEffect, useRef, useState } from 'react'
import video from '../../assets/videos/mindy.mp4';
import { ReactComponent as PlayIcon } from "../../assets/icons/icon-play-video.svg";

function VideoPlayer(props: any) {


    const videoRef = useRef<HTMLVideoElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };

    const handleVideoPlay = () => {
        setIsPlaying(true);
    };

    const handleVideoPause = () => {
        setIsPlaying(false);
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('play', handleVideoPlay);
            videoRef.current.addEventListener('pause', handleVideoPause);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play', handleVideoPlay);
                videoRef.current.removeEventListener('pause', handleVideoPause);
            }
        };
    }, []);

    return (
        <div className='video-player'  onClick={togglePlayPause}>
            <video
                ref={videoRef}
                width="100%"
                height="100%"
                preload="metadata"
                controls={false}
            >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {!isPlaying && (
                <button
                    className='video-player__btn'
                    ref={buttonRef}
                >
                    <PlayIcon />
                </button>
            )}
        </div>
    )
}

export default VideoPlayer