import React from "react";
import IconsTopics from "../general/IconsTopics";
import { ReactComponent as IconGood } from '../../assets/icons/topics/icon-good.svg';
import { ReactComponent as IconMid } from '../../assets/icons/topics/icon-mid.svg';
import { ReactComponent as IconBad } from '../../assets/icons/topics/icon-bad.svg';

export function MetricCategory(props:any) {
  return (
    <div className="metric-category">
      <div className="metric-category__container">
        <h3 className={"metric-category__title "} >{props.data.name}</h3>
        <div className={"metric-category__icon "}>
        {IconsTopics[props.data.icon] && React.createElement(IconsTopics[props.data.icon])}
        </div>
        <p className="metric-category__points">
        {
            props.data.score === -1
              ? "n.a."
              : props.data.maxIsGood
                ? props.data.score >= 0 && props.data.score <= 2
                  ? <IconGood />
                  : props.data.score > 2 && props.data.score <= 3
                    ? <IconMid />
                    : props.data.score > 3 && props.data.score <= 5
                      ? <IconBad />
                      : "error"
                : props.data.score >= 0 && props.data.score <= 2
                  ? <IconBad />
                  : props.data.score > 2 && props.data.score <= 3
                    ? <IconMid />
                    : props.data.score > 3 && props.data.score <= 5
                      ? <IconGood />
                      : "error"
          }
        </p>
        <small className="metric-category__date-edited">{props.data.dateEdited}</small>
      </div>
      
    </div>
  );
}