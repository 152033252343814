import { ReactComponent as IconJobSatisfaction } from '../../assets/icons/topics/icon-job-satisfaction.svg';
import { ReactComponent as IconWorkload } from '../../assets/icons/topics/icon-workload.svg';
import { ReactComponent as IconBurnout } from '../../assets/icons/topics/icon-burnout.svg';
import { ReactComponent as IconWorkLifeBalance } from '../../assets/icons/topics/icon-work-life-balance.svg';
import { ReactComponent as IconOrganizationalSupport } from '../../assets/icons/topics/icon-organizational-support.svg';
import { ReactComponent as IconWorkRelationships } from '../../assets/icons/topics/icon-work-relationships.svg';
import { ReactComponent as IconDiversityInclusion } from '../../assets/icons/topics/icon-diversity-inclusion.svg';
import { ReactComponent as IconOrganizationalJustice } from '../../assets/icons/topics/icon-organizational-justice.svg';
import { ReactComponent as IconPhysicalComplaints } from '../../assets/icons/topics/icon-physical-complaints.svg';
import { ReactComponent as IconEmotions } from '../../assets/icons/topics/icon-emotions.svg';
import { ReactComponent as IconSafety } from '../../assets/icons/topics/icon-safety.svg';
import { ReactComponent as IconPsychologicalNeeds } from '../../assets/icons/topics/icon-psychological-needs.svg';
import { ReactComponent as IconPsychologicalCapital } from '../../assets/icons/topics/icon-psychological-capital.svg';
import { ReactComponent as IconVitality } from '../../assets/icons/topics/icon-vitality.svg';
import { ReactComponent as IconLifeSatisfaction } from '../../assets/icons/topics/icon-life-satisfaction.svg';

const IconsTopics: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    'icon-job-satisfaction.svg': IconJobSatisfaction,
    'icon-workload.svg': IconWorkload,
    'icon-burnout.svg': IconBurnout,
    'icon-work-life-balance.svg': IconWorkLifeBalance,
    'icon-organizational-support.svg': IconOrganizationalSupport,
    'icon-work-relationships.svg': IconWorkRelationships,
    'icon-diversity-inclusion.svg': IconDiversityInclusion,
    'icon-organizational-justice.svg': IconOrganizationalJustice,
    'icon-physical-complaints.svg': IconPhysicalComplaints,
    'icon-emotions.svg': IconEmotions,
    'icon-safety.svg': IconSafety,
    'icon-psychological-needs.svg': IconPsychologicalNeeds,
    'icon-psychological-capital.svg': IconPsychologicalCapital,
    'icon-vitality.svg': IconVitality,
    'icon-life-satisfaction.svg': IconLifeSatisfaction,
    default: IconJobSatisfaction, // Set a default icon if needed
    null: IconJobSatisfaction, // Set a default icon if needed
};

export default IconsTopics;
