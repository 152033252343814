import { ReactComponent as IconEnthusiastic } from '../../assets/icons/emotions/icon-enthusiastic.svg';
import { ReactComponent as IconAnxious } from '../../assets/icons/emotions/icon-anxious.svg';
import { ReactComponent as IconInterested } from '../../assets/icons/emotions/icon-interested.svg';
import { ReactComponent as IconAngry } from '../../assets/icons/emotions/icon-angry.svg';
import { ReactComponent as IconDetermined } from '../../assets/icons/emotions/icon-determined.svg';
import { ReactComponent as IconStressed } from '../../assets/icons/emotions/icon-stressed.svg';
import { ReactComponent as IconIrritated } from '../../assets/icons/emotions/icon-irritated.svg';
import { ReactComponent as IconNervous } from '../../assets/icons/emotions/icon-nervous.svg';
import { ReactComponent as IconExcited } from '../../assets/icons/emotions/icon-excited.svg';
import { ReactComponent as IconActive } from '../../assets/icons/emotions/icon-active.svg';
import { ReactComponent as IconStrong } from '../../assets/icons/emotions/icon-strong.svg';
import { ReactComponent as IconHostile } from '../../assets/icons/emotions/icon-hostile.svg';
import { ReactComponent as IconEmbarrassed } from '../../assets/icons/emotions/icon-embarrassed.svg';
import { ReactComponent as IconProud } from '../../assets/icons/emotions/icon-proud.svg';
import { ReactComponent as IconGuilty } from '../../assets/icons/emotions/icon-guilty.svg';
import { ReactComponent as IconInspired } from '../../assets/icons/emotions/icon-inspired.svg';
import { ReactComponent as IconSad } from '../../assets/icons/emotions/icon-sad.svg';
import { ReactComponent as IconAlert } from '../../assets/icons/emotions/icon-alert.svg';
import { ReactComponent as IconDepressed } from '../../assets/icons/emotions/icon-depressed.svg';

const IconsEmotions: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    'icon-enthusiastic.svg': IconEnthusiastic,
    'icon-anxious.svg': IconAnxious,
    'icon-interested.svg': IconInterested,
    'icon-angry.svg': IconAngry,
    'icon-determined.svg': IconDetermined,
    'icon-stressed.svg': IconStressed,
    'icon-irritated.svg': IconIrritated,
    'icon-nervous.svg': IconNervous,
    'icon-excited.svg': IconExcited,
    'icon-active.svg': IconActive,
    'icon-strong.svg': IconStrong,
    'icon-hostile.svg': IconHostile,
    'icon-embarrassed.svg': IconEmbarrassed,
    'icon-proud.svg': IconProud,
    'icon-guilty.svg': IconGuilty,
    'icon-inspired.svg': IconInspired,
    'icon-sad.svg': IconSad,
    'icon-alert.svg': IconAlert,
    'icon-depressed.svg': IconDepressed,
    default: IconEnthusiastic,
    null: IconEnthusiastic,
};

export default IconsEmotions;
