import { useState } from 'react'
import { ReactComponent as EnergyDashboardIcon } from "../assets/icons/icon-energy-dashboard.svg";
import { ReactComponent as WorkBehaviourIcon } from "../assets/icons/icon-work-behaviour.svg";
import { ReactComponent as SentimentIcon } from "../assets/icons/icon-sentiment.svg";
import { ReactComponent as SurveyMetricsIcon } from "../assets/icons/icon-survey-metrics.svg";
import EnergyDashboard from "./hr/EnergyDashboard";
import WorkBehaviour from "./hr/WorkBehaviour";
import Sentiment from "./hr/Sentiment";
import SurveyMetrics from "./hr/SurveyMetrics";

function HR(props:any) {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <main className="hr">
            <ol className="tabs">
                <li className={(activeTab===0)?("tabs__title active"):("tabs__title")} onClick={() => setActiveTab(0)}><EnergyDashboardIcon/>{window.matchMedia("(max-width: 767px)").matches ? 'Energy' : 'Energy Dashboard'}</li>
                <li className={(activeTab===1)?("tabs__title active"):("tabs__title")} onClick={() => setActiveTab(1)}><WorkBehaviourIcon/>{window.matchMedia("(max-width: 767px)").matches ? 'Behaviour' : 'Work Behaviour'}</li>
                <li className={(activeTab===2)?("tabs__title active"):("tabs__title")} onClick={() => setActiveTab(2)}><SentimentIcon/>Sentiments</li>
                <li className={(activeTab===3)?("tabs__title active"):("tabs__title")} onClick={() => setActiveTab(3)}><SurveyMetricsIcon/>{window.matchMedia("(max-width: 767px)").matches ? 'Survey & Metrics' : 'Wellbeing Surveys'}</li>
            </ol>
            <div className="tab-content">
                {(activeTab === 0) ? (
                    <EnergyDashboard token={props.token} filters={props.filters}/>
                ) : (activeTab === 1) ? (
                    <WorkBehaviour token={props.token} filters={props.filters}/>
                ) : (activeTab === 2) ? (
                    <Sentiment token={props.token} filters={props.filters}/>
                ) : (activeTab === 3) ? (
                    <SurveyMetrics token={props.token} filters={props.filters}/>
                ): (
                    <EnergyDashboard token={props.token}/>
                )
                }
            </div>
        </main>
    )
}

export default HR
