import { useEffect, useState } from 'react'

import { ReactComponent as IconPlus } from "../../assets/icons/icon-plus.svg";

import { SurveyListItem } from "./SurveyListItem";
import { Modal } from "./ModalHRNewSurvey";
import { useModal } from "../general/useModal";
import { MetricCategory } from "./MetricCategory";
import { ModalHRNewTopic } from "./ModalHRNewTopic";
import { ModalSurveyList } from './ModalSurveyList';

function SurveyMetrics(props: any) {
    const [activeTab, setActiveTab] = useState(0);
    const { isShown: isModalNewSurveyShown, toggle: toggleNewSurveyModal } = useModal();
    const { isShown: isModalNewTopicShown, toggle: toggleNewTopicModal } = useModal();
    const { isShown: isModalSurveyListShown, toggle: toggleSurveyListModal } = useModal();
    const [surveys, setSurveys] = useState<any>();
    const [categories, setCategories] = useState<any>();
    const [topics, setTopics] = useState<any>();

    useEffect(() => {
        getSurveys();
    }, [])

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        setTopics(categories[index]?.topics || []);
    };

    const getSurveys = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/Survey", options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setSurveys(data.surveys);
        setCategories(data.categories);
        setTopics(data.categories[0]?.topics || []);
    }

    const refreshTopics = () => {
        getSurveys();
    }

    return (
        <div className="hr-platform">
            <section className="hr-platform__surveys-container | bg-white">
                <div className="header">
                    <h2 className="header__title | heading-l txt-primary">Want some more insights?</h2>
                    <p className="header__text | txt-lightgrey">You can gather more information about all/any departments by choosing a couple subjects of interest.</p>
                    <button className="header__btn-new-survey | txt-white txt-upper body-2" onClick={toggleNewSurveyModal}>Make new survey</button>
                    <Modal isShown={isModalNewSurveyShown} hide={toggleNewSurveyModal} headerText={""} token={props.token} categories={categories} />
                </div>
                <div className="content">
                    <ul className="survey-list">
                        {(!surveys) ? ("Loading...") : (surveys.length === 0) ? (<p style={{ alignSelf: "center" }}>You havent made any surveys yet.</p>) : (
                            surveys.slice(0, 4).map((survey: any, index: any) => {
                                return <SurveyListItem key={index} data={survey} token={props.token}/>
                                // return <MetricCategory key={item.id} data={item} />
                            })
                        )}
                    </ul>
                    {/* TODO: add button and surveylist modal(no design yet) */}
                    <button className="btn-view-all" onClick={toggleSurveyListModal}>View all</button>
                    <ModalSurveyList isShown={isModalSurveyListShown} hide={toggleSurveyListModal} token={props.token} surveys={surveys} />
                </div>
            </section>

            <section className="hr-platform__metrics-container">
                <div className="header">
                    <div className="header__heading">
                        <h2 className="header__title | heading-l txt-primary">MINDTHRIVEN’s Wellbeing <span className="txt-hr">Topics</span></h2>
                        <button className="header__btn-new-topic | txt-white txt-upper body-2" onClick={toggleNewTopicModal}>Create new <IconPlus /></button>
                        <ModalHRNewTopic isShown={isModalNewTopicShown} hide={toggleNewTopicModal} token={props.token} categories={categories} refreshTopics={refreshTopics}/>
                    </div>

                    <ol className="metrics-tabs">
                        {(!categories) ? (<li className="metrics-tabs__title active">Loading</li>) : (
                            categories.map((category: any, index: number) => (
                                <li
                                    key={category.id}
                                    className={activeTab === index ? "metrics-tabs__title active" : "metrics-tabs__title"}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {category.name}
                                </li>
                            ))
                        )}

                    </ol>
                </div>
                <div className="content">
                    {(!topics) ? ("Loading...") : (
                        topics.map((topic: any) => (
                            <MetricCategory key={topic.id} data={topic} token={props.token}/>
                        ))
                    )}
                    {/* {MetricCategories.map((item: any) => {
                        return <MetricCategory key={item.id} data={item} />
                    })} */}
                </div>
            </section>
        </div>
    )
}

export default SurveyMetrics