import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as DeleteIcon } from "../../assets/icons/advices/icon-delete.svg";


// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import { SurveyListItem } from './SurveyListItem';
import IconsAdvices from '../general/IconsAdvices';
import { toast } from 'react-toastify';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    advice: any;
    token: string;
    refreshAdvices: () => void;
}

export const ModalQuestDelete: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    advice,
    token,
    refreshAdvices,
}) => {

    console.log("got advice: " + JSON.stringify(advice));


    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

    console.log("selected answer: " + selectedAnswer)
    const handleAnswerChange = (score: number) => {
        console.log("clicked answer with score " + score);
        setSelectedAnswer(score);
    };

    const deleteQuest = async () => {
        try {
            var options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    score: selectedAnswer,
                    adviceId: advice.id
                })
            }

            const response = await fetch("https://mindy-api.azurewebsites.net/Quest/Reject", options);

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            refreshAdvices();
            hide();

            toast.success("Quest rejected.")

        } catch (error) {
            // Handle the error here
            console.error(error);
            toast.error("Quest could not be deleted, please contact us.");
        }
    }

    const modalQuestDelete = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    {(!advice) ? ("No Advice") : (
                        <div className="modal-quest">
                            <div className='modal-quest__body--delete'>
                                <DeleteIcon />
                                <h2>You're about to delete a quest!</h2>
                                <p>Are you sure you want to delete this quest and miss the opportunity to work on your well-being regarding this topic?</p>
                                <hr/>
                                <div>
                                    <p>Please clarify your decision:</p>
                                    <div className='modal-quest__body--delete__question__answers'>
                                        <div>
                                            <input type="radio" id={"a1"} name={"question"} checked={selectedAnswer === 0} value={0} onChange={() => handleAnswerChange(0)} />
                                            <label htmlFor={"a1"}>Because this isn't relevant for me.</label>
                                        </div>
                                        <div>
                                            <input type="radio" id={"a2"} name={"question" } checked={selectedAnswer === 1} value={1} onChange={() => handleAnswerChange(1)} />
                                            <label htmlFor={"a2" }>I can't change this habit...</label>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div className='modal-quest__footer--delete'>
                                <button className="btn btn--primary--outline" onClick={hide}>Cancel</button>
                                <button className="btn btn--accent" disabled={selectedAnswer === null ? true : false} onClick={deleteQuest}>Delete Quest</button>
                            </div>
                        </div>
                    )}
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalQuestDelete, rootContainer) : null;
};