import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconList } from "../../assets/icons/icon_list.svg";
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconShield } from "../../assets/icons/icon_shield_small.svg";
import { ReactComponent as IconTime } from "../../assets/icons/icon_time_small.svg";

import { ReactComponent as UserEngagementSurvey } from "../../assets/img/illustration_user_engagement_survey.svg";

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  // Content,
  Backdrop,
} from '../general/modal.style';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  // modalContent: JSX.Element;
  headerText: string;
  hrSurveyId: number;
  token: string;
  userId: number;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  // modalContent,
  hrSurveyId,
  token,
  userId
}) => {

  const [surveyDetails, setSurveyDetails] = useState<any>();

  useEffect(() => {
    getSurveyDetails();
  }, [])

  const getSurveyDetails = async () => {
    var options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }

    const response = await fetch("https://mindy-api.azurewebsites.net/Survey/stats?surveyId=" + hrSurveyId + "&userId=" + userId, options);
    const data = await response.json();

    console.log(response);
    console.log(data);
    setSurveyDetails(data)
  }


  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          {/* <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>X</CloseButton>
          </Header> */}
          <div className="model-hr__details-survey">
            <button className="btn-close-modal no-bg" onClick={hide}><IconClose /></button>

            {/* <div className="left">
              <h1 className="left__title | heading-l txt-primary">Interest Mindfulness</h1>
              <p className='left__date'>03/03</p>
              <div className="departments">
                <h3 className="departments__title | body-2 txt-upper txt-primary">Departments<span>(6)</span></h3>
                <ul className="departments__list">
                  <li className="departments__item">
                    <p className="body-3">HR</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Marketing</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">IT</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Sales</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Finance</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Customer support</p>
                  </li>
                </ul>
              </div>
              <div className="user-engagement">
                <h3 className="user-engagement__title | body-2 txt-upper txt-primary">User engagement <span>(350 People questioned)</span></h3>
                <UserEngagementSurvey />
              </div>
            </div> */}

            <div className="right">
              <div className="header">
                <h3 className="header__title | txt-primary"><IconList /> Results "{(!surveyDetails)?("..."):(surveyDetails.title)}"</h3>
                {/* TODO: add dropdown */}
              </div>
              <div className="content">
                <ul className="content__list">

                  {(!surveyDetails) ? ("loading...") : (
                    surveyDetails.topics.map((item: any) => {
                      return( 
                      <li className="content__item">
                        <div>
                          <IconShield className="icon bg-hr" />
                          <div className="text">
                            <p className="body-1 txt-hr">{item.name}</p>
                            {/* <p>Latest data: 22/01 | 10:45pm</p> */}
                          </div>
                          <p className="score | body-2 txt-primary">{item.score}/5</p>
                        </div>
                      </li>
                      )
                    })
                  )}
                  {/* <li className="content__item">
                    <div>
                      <IconSteps className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Work pressure</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">3.8/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconRemove className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Work-Life conflict</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">4.5/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconShield className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Physical strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">3.5/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconTime className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Mental strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">4/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconSteps className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Emotional strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">2.4/5</p>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  const rootContainer = document.getElementById('root');
  return isShown && rootContainer ? ReactDOM.createPortal(modal, rootContainer) : null;
};