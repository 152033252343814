import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconBackgroundProfile } from "../../assets/img/background-img-profile.svg";

import { toast } from 'react-toastify';

import {
    Wrapper,
    StyledModal,
    Backdrop,
} from '../general/modal.style';
import { ReactComponent as MMIcon } from "../../assets/icons/icon-mm.svg";

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
}

export const ModalNotEnoughEmployees: FunctionComponent<ModalProps> = ({
    isShown,
    hide,

}) => {
    
    const modalNotEnoughEmployees = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-no-data">
                        {/* <button className="btn-close-modal" onClick={hide}><IconClose /></button> */}
                        <MMIcon style={{ marginBottom: 20 }} />
                        <h2 style={{textAlign:'center'}}>Gathering More Insights</h2>
                        <p style={{textAlign:'center'}}>Mindy's dashboard will be available once we have data from 10 or more employees to ensure anonymity and comprehensive insights. Stay tuned!</p>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );
    

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalNotEnoughEmployees, rootContainer) : null;
};