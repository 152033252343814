import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconList } from "../../assets/icons/icon_list.svg";


import { ReactComponent as IconCalendar } from "../../../assets/icon_calendar_survey.svg";
import { ReactComponent as IconFiles } from "../../assets/icons/icon_files.svg";
import { ReactComponent as IconAudience } from "../../assets/icons/icon_audience.svg";
import { ReactComponent as IconLungs } from "../../../assets/icon_lungs.svg";
import { ReactComponent as IconSpeaker } from "../../../assets/icon_speaker.svg";
import { ReactComponent as IconMoney } from "../../../assets/icon_money.svg";

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";

import { toast } from 'react-toastify';


// import Swal from 'sweetalert2';

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  Content,
  Backdrop,
} from '../general/modal.style';
import Loader from '../general/Loader';
import { useModal } from '../general/useModal';
import { ModalScheduleSurvey } from './ModalScheduleSurvey';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  // modalContent: JSX.Element;
  headerText: string;
  token: string;
  categories: [];
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  // modalContent,
  token,
  headerText,
  categories
}) => {

  const [loading, setLoading] = useState<Boolean>(false);
  const { isShown: isModalScheduleSurveyShown, toggle: toggleScheduleSurveyModal } = useModal();
  
  const [departments, setDepartments] = useState<any>();
  const [categoryList, setCategoryList] = useState<any>([]);
  const [departmentList, setDepartmentList] = useState<any>([]);

  const [name, setName] = useState<string>("");
  const [deadline, setDeadline] = useState<string>('');
  const [epochDeadline, setEpochDeadline] = useState<number | null>(null);


  console.log("categoryList: " + categoryList);
  console.log("departmentList: " + departmentList);

  useEffect(() => {
    getDepartments();
  }, [])

  const resetState = () => {
    setCategoryList([]);
    setDepartmentList([]);
    setName("");
    setDeadline("");
    setEpochDeadline(null);
  };

  const getDepartments = async () => {
    var options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }

    const response = await fetch("https://mindy-api.azurewebsites.net/Departments", options);
    const data = await response.json();

    console.log(response);
    console.log(data);
    setDepartments(data);
  }

  const selectCategory = (event: any) => {
    console.log("selecting category: ");
    console.log(event.target.dataset);
    var id = event.target.dataset.id
    var name = event.target.dataset.name;
    if (event.target.checked) {
      console.log("checked");
      console.log("adding to categoryList: " + id + name);

      const newCategoryList = categoryList.concat({ id: id, name: name });
      setCategoryList(newCategoryList);
    } else {
      console.log("not checked");
      console.log("removing from categoryList: " + id + name);

      setCategoryList((current: any) =>
        current.filter((category: any) => category.id !== id)
      );
    }

  }

  const selectDepartment = (event: any) => {
    console.log("selecting department: ");
    console.log(event.target.dataset);
    var id = event.target.dataset.id
    var name = event.target.dataset.name;
    if (event.target.checked) {
      console.log("checked");
      console.log("adding to departmentList: " + id + name);

      const newDepartmentList = departmentList.concat({ id: id, name: name });
      setDepartmentList(newDepartmentList);
    } else {
      console.log("not checked");
      console.log("removing from departmentList: " + id + name);

      setDepartmentList((current: any) =>
        current.filter((department: any) => department.id !== id)
      );
    }
  }

  const postSurvey = async (startEpoch: number) => {
    setLoading(true);
    var survey = {
      name: name,
      categories: categoryList.map((category: { id: string; }) => category.id),
      departments: departmentList.map((department: { id: string; }) => department.id),
      startEpoch: startEpoch,
      endEpoch: epochDeadline
    }
    var options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify(survey)
    }

    try {
      const response = await fetch("https://mindy-api.azurewebsites.net/Survey", options);

      if (!response.ok) {
        throw new Error(`Server returned ${response.status}`);
      }

      toast.success("Survey created.");

      hide();
      resetState();
    } catch (error) {
      toast.error("Survey creation failed.");
      console.log(error);
    }

    setLoading(false);
  }

  const openDatePicker = () => {
    const datepicker = document.getElementById("deadline-datepicker");
    console.log(datepicker);
    datepicker?.click();
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateString = event.target.value;
    setDeadline(selectedDateString);

    if (selectedDateString) {
      const selectedEpochTime = Math.floor(new Date(selectedDateString).getTime());
      setEpochDeadline(selectedEpochTime);
    } else {
      setEpochDeadline(null);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    setName(selectedDate);
  };


  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          {/* <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>X</CloseButton>
          </Header> */}
          <div className="model-hr__new-survey">
            <button className="btn-close-modal" onClick={()=>{hide(); resetState();}}><IconClose /></button>
            {(loading) ? (<Loader />) : (
              <>
                <div className="new-survey__content">
                  <h1 className="title | heading-l txt-primary">Create your survey!</h1>
                  <div className="survey-content-top">
                    <input className="survey-name" type="text" placeholder="Type in the survey's name..." value={name} onChange={handleNameChange} />
                    <div className="deadline" onClick={openDatePicker}>
                      <p className="deadline__label | txt-upper txt-primary">Deadline:</p>
                      <input className="deadline__date" type={'date'} id="deadline-datepicker" value={deadline} onChange={handleDateChange} min={new Date().toISOString().split('T')[0]}/>
                      {/* <div className="deadline__date" >
                    <input type={'date'} style={{width:0}} id="deadline-datepicker"/>
                    <IconCalendar />
                    <p>.../...</p>
                  </div> */}
                    </div>
                  </div>
                  <div className="survey-content-bottom">
                    <div className="categories">
                      <h3 className="categories-title | body-1"><IconFiles /> Select category('s)</h3>
                      <ul className="categories-list" style={{ overflowY: 'scroll', maxHeight: 300 }}>
                        {
                          (!categories) ? ("loading...") : (
                            categories.map((wellbeingCategory: any) => (
                              wellbeingCategory.topics.map((topic: any) => (
                                <li className="categories-item" key={topic.id}>
                                  <div>
                                    {/* <IconSteps /> */}
                                    <p>{topic.name}</p>
                                    <label className="toggle" htmlFor={"categories-item-" + topic.id}>
                                      <input type="checkbox" className="toggle__input" id={"categories-item-" + topic.id} onChange={selectCategory} data-name={topic.name} data-id={topic.id} />
                                      <span className="toggle-track">
                                        <span className="toggle-indicator">
                                          <span className="checkMark">
                                            <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                                              <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                                            </svg>
                                          </span>
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </li>
                              ))
                            ))
                          )
                        }
                      </ul>
                    </div>
                    <div className="audience">
                      <h3 className="audience-title | body-1"><IconAudience /> Select audience</h3>
                      <ul className="audience-list" style={{ overflowY: 'scroll', maxHeight: 300 }}>
                        {
                          (!departments) ? ("loading...") : (
                            departments.departments.map((department: any) => (
                              <li key={department.id} className="categories-item">
                                <div>
                                  {/* <IconSpeaker /> */}
                                  <p>{department.name}</p>
                                  <label className="toggle" htmlFor={"audience-item-" + department.id}>
                                    <input type="checkbox" className="toggle__input" id={"audience-item-" + department.id} onChange={selectDepartment} data-id={department.id} data-name={department.name} />
                                    <span className="toggle-track">
                                      <span className="toggle-indicator">
                                        <span className="checkMark">
                                          <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                                            <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                                          </svg>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </li>
                            ))
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </div>

                <div className='new-survey__summary'>
                  <div className="header">
                    <h2 className="header__title | txt-primary"><IconList /> Summary</h2>
                    {/* <p className="header__time | body-3 txt-primary"><IconTimer /> ...min</p> */}
                  </div>

                  <div className="content">
                    <div className="content-subjects">
                      <h3 className="content-subjects__title | txt-upper txt-primary">Subjects<span>({categoryList.length})</span></h3>
                      <ul className="content-subjects__list" id="content-subjects__list">
                        {
                          categoryList.map((category: any) => (
                            <li key={category.name} className="content-subjects__item">
                              <p>{category.name}</p>
                            </li>
                          ))
                        }
                        {/* <li className="content-subjects__item">
                      <IconSteps />
                      <p>Mindfulness</p>
                      <p>3min</p>
                      <button className="btn"><IconRemove /></button>
                    </li>
                    <li className="content-subjects__item">
                      <IconSteps />
                      <p>Mindfulness</p>
                      <p>4min</p>
                      <button className="btn"><IconRemove /></button>
                    </li> */}
                      </ul>
                    </div>
                    <div className="content-departments">
                      <div className="content-departments__header">
                        <h3 className="content-departments__title | txt-upper txt-primary">Departments<span>({departmentList.length})</span></h3>
                        {/* <p className="content-departments__people | body-3 txt-primary"><IconPeople /> ...</p> */}
                      </div>
                      <ul className="content-departments__list">
                        {
                          departmentList.map((department: any) => (
                            <li key={department.name} className="content-departments__item"><p>{department.name}</p></li>
                          ))
                        }
                        {/* <li className="content-departments__item"><p>HR (16) <IconRemove /></p></li>
                    <li className="content-departments__item"><p>Marketing (26) <IconRemove /></p></li>
                    <li className="content-departments__item"><p>Sales (45) <IconRemove /></p></li>
                    <li className="content-departments__item"><p>Finance (6) <IconRemove /></p></li>
                    <li className="content-departments__item"><p>Customer support (10) <IconRemove /></p></li>
                    <li className="content-departments__item"><p>IT (6) <IconRemove /></p></li> */}
                      </ul>
                    </div>
                    <div className="btn-group">
                      <button type="submit" className="btn btn--accent" onClick={()=>{postSurvey(Math.floor(new Date().getTime()))}}>Send now</button>
                      <button type="submit" className="btn btn--primary--outline" onClick={toggleScheduleSurveyModal}>Schedule send</button>
                      <ModalScheduleSurvey isShown={isModalScheduleSurveyShown} hide={toggleScheduleSurveyModal} postSurvey={postSurvey}/>
                    </div>
                  </div>
                </div>
              </>
            )}

          </div>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );
  

  const rootContainer = document.getElementById('root');
  return isShown && rootContainer? ReactDOM.createPortal(modal, rootContainer) : null;
};