function DropdownSentiment(props: any) {
    console.log("props dropdown sentiment" + JSON.stringify(props));

    return (
        <select className="dropdown-sentiment" value={props.selectedValue} onChange={props.onDropdownChange}>
            {(!props.sentiments) ? (<option value="loading">loading...</option>) : (
                props.sentiments.averages.map((sentiment: any) => {
                    return <option value={sentiment.emotionId} key={sentiment.emotionId}>{sentiment.emotion}</option>
                    // return <MetricCategory key={item.id} data={item} />
                })
            )}
            
        </select>
    )
}

export default DropdownSentiment;