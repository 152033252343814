import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconBackgroundProfile } from "../../assets/img/background-img-profile.svg";

import { toast } from 'react-toastify';

import {
    Wrapper,
    StyledModal,
    Backdrop,
} from '../general/modal.style';
import { useModal } from './useModal';
import { ModalVideoPlayer } from './ModalVideo';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    fullName: string;
    profilePicture: string;
    token: string;
}

export const ModalProfile: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    fullName,
    token
}) => {
    const [productiveTime, setProductiveTime] = useState<any>(0);
    const [isHR, setIsHR] = useState<any>(false);
    const [consent, setConsent] = useState<any>();
    const { isShown: isModalVideoShown, toggle: toggleVideoModal } = useModal();

    useEffect(() => {
        getUser();
    }, [])

    const getUser = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/User", options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setProductiveTime(data.productiveTime);
        setIsHR(data.isHR)
        setConsent(data.privacyConsent)
    }

    const handleProductiveTimeChange = (event: any) => {
        const value = parseInt(event.target.value);
        setProductiveTime(value);
    };

    const updateProfile = async () => {

        var user = {
            productiveTime: productiveTime
        }
        var options = {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(user)
        }

        try {
            const response = await fetch("https://mindy-api.azurewebsites.net/Employee", options);
            //const data = await response.json();

            console.log(response);
            //console.log(data);
            toast.success("Profile updated.");

            hide();
        } catch (error) {
            toast.error("Profile update failed.");
            console.log(error);
        }
    }

    const changeConsent = async () => {

        var user = {
            privacyConsent: consent ? false : true
        }
        var options = {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(user)
        }

        try {
            const response = await fetch("https://mindy-api.azurewebsites.net/Employee", options);
            //const data = await response.json();

            console.log(response);
            //console.log(data);
            toast.success("Profile updated.");

            hide();
        } catch (error) {
            toast.error("Profile update failed.");
            console.log(error);
        }
    }

    const openLinkInBrowser = (url: string) => {
        window.open(url, '_blank');
    };


    const modalProfile = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-profile">
                        <IconBackgroundProfile className='modal-profile__background' />
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        <div className='modal-profile__header'>
                            {/* <h3 className='modal-profile__header__title'>{fullName}</h3> */}
                        </div>
                        <div className='modal-profile__body'>
                            <h2 className='modal-profile__body__title'>Preferences</h2>

                            <p className="modal-profile__body__question">What time during the day are you the most productive?</p>
                            <label className="modal-profile__body__label">
                                <select value={productiveTime} onChange={handleProductiveTimeChange}>
                                    <option value={0}>Before Noon</option>
                                    <option value={1}>After Noon</option>
                                </select>
                            </label>
                            <button className='modal-profile__body__btn' onClick={updateProfile} >Save</button>
                            <div className='modal-profile__body__gdpr'>
                                <div className='modal-profile__body__gdpr__btn-grp'>

                                    <a href='https://mindthriven.zohodesk.eu/portal/en/kb/mindy/employee' onClick={(e) => {
                                        e.preventDefault();
                                        openLinkInBrowser(e.currentTarget.href);
                                    }}>Employee Manual</a>
                                    {(isHR) && (
                                        <a href='https://mindthriven.zohodesk.eu/portal/en/kb/mindy/for-hr' onClick={(e) => {
                                            e.preventDefault();
                                            openLinkInBrowser(e.currentTarget.href);
                                        }}>HR Manual</a>
                                    )}
                                    <a href='https://mindthriven.zohodesk.eu/portal/en/newticket?departmentId=151920000000007061' onClick={(e) => {
                                        e.preventDefault();
                                        openLinkInBrowser(e.currentTarget.href);
                                    }}>Submit a ticket</a>
                                    <button className={(consent) ? ("error") : ("hr")} onClick={changeConsent}>{(consent) ? ("Withdraw Consent") : ("Give Consent")}</button>
                                </div>
                                <div className='modal-profile__body__gdpr__links'>
                                    <p onClick={toggleVideoModal}>What happens with my data?</p>
                                    <a href="https://mindthriven.zohodesk.eu/portal/en/kb/articles/privacy-notice" target="_blank">Privacy policy</a>
                                    <ModalVideoPlayer hide={toggleVideoModal} isShown={isModalVideoShown} />
                                </div>
                            </div>
                        </div>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalProfile, rootContainer) : null;
};