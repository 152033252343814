import { useState, useEffect } from 'react';
import { ReactComponent as HandWaveIcon } from "../../assets/icons/icon-hand-wave.svg";
import { ReactComponent as SuitcaseIcon } from "../../assets/icons/icon-suitcase-small.svg";
import { PieChart, Pie, Cell, ResponsiveContainer, RadialBarChart, RadialBar, Legend } from 'recharts';

import { toast } from "react-toastify";
import React from "react";
import IconsEmotions from "../general/IconsEmotions";

function DailyCheckin(props: any) {
    const [sentiments, setSentiments] = useState<any>();
    const [activeIndex, setActiveIndex] = useState(null);
    const [value, setValue] = useState(-25);

    const RADIAN = Math.PI / 180;
    const data = [
        { name: 'A', value: 20, color: '#F03944', score: 1 },
        { name: 'B', value: 20, color: '#FECA24', score: 2 },
        { name: 'C', value: 20, color: '#F2878D', score: 3 },
        { name: 'D', value: 20, color: '#C173FE', score: 4 },
        { name: 'E', value: 20, color: '#076CFB', score: 5 }
    ]
    const cx = 0;
    const cy = 0;
    const iR = 50;
    const oR = 58;
    //let value = 50;



    useEffect(() => {
        getSentiment();
    }, [])

    const getSentiment = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }
        try {
            const response = await fetch("https://mindy-api.azurewebsites.net/GraphQL/sentiment?userId="+props.user.userId, options);
            const data = await response.json();

            console.log(response);
            console.log(data);
            setSentiments(data);

        } catch (error) {
            console.log('Error getting emotions ', error);
            toast.error("ERROR getting emotions: " + error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const renderCustomizedLabel = (props: any) => {
        const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;
        const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
        const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

        return (
            <svg x={x - 20} y={y - 20} width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                {(props.payload.payload.score === 1) ? (
                    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 13.3822C12.3896 13.3822 12.7164 13.2501 12.9804 12.9859C13.2444 12.7218 13.3759 12.3953 13.375 12.0064V7.87898C13.375 7.48917 13.243 7.16219 12.979 6.89804C12.715 6.63389 12.3887 6.50227 12 6.50318C11.6104 6.50318 11.2836 6.63526 11.0196 6.89941C10.7556 7.16357 10.6241 7.49009 10.625 7.87898V12.0064C10.625 12.3962 10.757 12.7232 11.021 12.9873C11.285 13.2515 11.6113 13.3831 12 13.3822ZM12 18.8854C12.3896 18.8854 12.7164 18.7533 12.9804 18.4891C13.2444 18.225 13.3759 17.8984 13.375 17.5096C13.375 17.1197 13.243 16.7928 12.979 16.5286C12.715 16.2645 12.3887 16.1328 12 16.1338C11.6104 16.1338 11.2836 16.2658 11.0196 16.53C10.7556 16.7941 10.6241 17.1207 10.625 17.5096C10.625 17.8994 10.757 18.2263 11.021 18.4905C11.285 18.7547 11.6113 18.8863 12 18.8854ZM12 28C11.8167 28 11.6333 27.9656 11.45 27.8968C11.2667 27.828 11.1063 27.7363 10.9688 27.6217C7.62292 24.6637 5.125 21.9176 3.475 19.3834C1.825 16.8492 1 14.4819 1 12.2815C1 8.84204 2.10596 6.10191 4.31788 4.06115C6.52979 2.02038 9.0905 1 12 1C14.9104 1 17.4716 2.02038 19.6835 4.06115C21.8954 6.10191 23.0009 8.84204 23 12.2815C23 14.4828 22.175 16.8505 20.525 19.3848C18.875 21.919 16.3771 24.6646 13.0312 27.6217C12.8937 27.7363 12.7333 27.828 12.55 27.8968C12.3667 27.9656 12.1833 28 12 28Z" stroke={activeIndex === 0 ? 'white' : '#01195D'} stroke-width="1.5" />
                    </svg>
                ) : (props.payload.payload.score == 2) ? (
                    <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.90478 14.3248C1.61869 15.0135 1.2693 15.6768 1.21107 16.4338C1.15284 17.1908 1.41361 17.9605 2.03137 18.3478C2.37822 18.5656 2.7985 18.6339 3.20612 18.682C5.12268 18.9175 7.06203 18.8744 8.98872 18.7757C9.58876 18.7453 10.2293 18.6947 10.7103 18.3326C11.1534 18.001 11.3889 17.4465 11.4496 16.8946C11.5408 16.0464 11.361 15.1274 10.8116 14.459C10.1989 13.7121 8.92796 13.8033 8.03424 13.783C6.1354 13.74 3.74539 13.3931 2.01871 14.3147" stroke={activeIndex === 1 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.7969 18.525C11.5412 19.239 11.726 20.3631 11.6425 21.391C11.5589 22.4189 11.2475 23.4164 11.1614 24.4418C11.0399 25.9077 11.4222 27.3964 12.1843 28.6522C12.678 29.4649 14.3642 31.6929 15.5389 31.3106C16.4453 31.0169 16.263 29.594 16.2402 28.8952C16.144 26.1432 17.3542 23.4975 18.8707 21.2695C19.6657 20.1023 20.5746 19.0111 21.5595 17.9984C22.4507 17.0819 23.7444 16.3097 24.2685 15.1172C24.5749 14.4235 24.5622 13.6361 24.5445 12.8791C24.423 7.84848 23.8153 2.10385 17.8327 1.22279C15.4756 0.875932 12.5337 1.21266 10.1816 1.47597" stroke={activeIndex === 1 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.1225 9.13967C12.8719 7.94214 12.9226 5.68125 11.4086 5.01032C10.8541 4.76474 10.2313 4.8331 9.64137 4.83563C8.95778 4.83563 8.2742 4.83816 7.59062 4.84069C6.22345 4.84323 4.85629 4.84576 3.48659 4.85082C3.21569 4.85082 2.92959 4.85082 2.69414 4.7141C2.49919 4.60271 2.35741 4.41282 2.26373 4.20775C2.1017 3.86596 2.0536 3.471 2.12195 3.10135C2.24601 2.44562 2.73211 1.89876 3.31443 1.57215C3.89674 1.24555 4.57019 1.11643 5.23352 1.05314C6.53739 0.92908 7.85392 1.04048 9.15273 1.19998C9.50718 1.24302 9.87176 1.29366 10.1781 1.47595C10.4819 1.6557 10.7047 1.95445 10.8516 2.27599C11.0212 2.6431 11.2592 3.20263 11.206 3.61024C11.1326 4.17737 10.4313 4.44067 10.092 4.8331" stroke={activeIndex === 1 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.76755 9.26637C1.29664 8.49417 1.09916 7.59539 1.31689 6.7042C1.49665 5.96492 1.91186 5.29652 2.41822 4.73193" stroke={activeIndex === 1 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M1.89222 14.193C0.292132 12.7195 0.871912 8.91929 3.40876 9.13956C5.8089 9.14715 8.2065 9.13956 10.6066 9.13956C13.7714 8.90916 13.8676 13.5018 11.4902 14.6968" stroke={activeIndex === 1 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                ) : (props.payload.payload.score == 3) ? (
                    <svg width="33" height="26" viewBox="0 0 33 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0711 1.90786C17.3799 1.62177 16.7166 1.27239 15.9571 1.21416C15.1975 1.15593 14.4279 1.4167 14.038 2.03699C13.8202 2.38638 13.7519 2.80665 13.7013 3.21427C13.4658 5.1359 13.5063 7.07778 13.6076 9.01206C13.638 9.61463 13.6886 10.2552 14.0506 10.7362C14.3848 11.1818 14.9393 11.4173 15.4912 11.4755C16.3419 11.5666 17.261 11.3869 17.9319 10.8375C18.6813 10.2223 18.5876 8.94877 18.6104 8.05252C18.6534 6.14862 19.0003 3.75354 18.0762 2.0218" stroke={activeIndex === 2 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.8615 10.8199C13.1476 11.5642 12.0184 11.7516 10.988 11.668C9.95752 11.5845 8.96 11.2705 7.92956 11.1844C6.46112 11.0629 4.96737 11.4452 3.70907 12.2098C2.89636 12.7035 0.663326 14.3947 1.04309 15.572C1.33931 16.4809 2.76218 16.2986 3.46348 16.2733C6.2206 16.1771 8.87392 17.3898 11.1095 18.9115C12.2792 19.709 13.3729 20.6179 14.3882 21.6053C15.3072 22.499 16.0794 23.7953 17.2744 24.3219C17.9706 24.6282 18.758 24.6156 19.5201 24.5978C24.5634 24.4763 30.3207 23.8662 31.2043 17.8709C31.5537 15.5062 31.2144 12.5592 30.9486 10.1996" stroke={activeIndex === 2 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.2695 12.1491C24.4696 12.901 26.7356 12.9517 27.409 11.4326C27.6546 10.8756 27.5862 10.2528 27.5837 9.66287C27.5837 8.97676 27.5812 8.29064 27.5786 7.60706C27.5761 6.23483 27.5736 4.86513 27.5685 3.4929C27.5685 3.222 27.5685 2.93591 27.7052 2.69792C27.8166 2.50044 28.0065 2.36119 28.2116 2.26498C28.5559 2.10295 28.9483 2.05231 29.3205 2.1232C29.9788 2.24726 30.5256 2.7359 30.8548 3.31821C31.1839 3.90052 31.3105 4.57651 31.3763 5.24237C31.5004 6.54877 31.389 7.86783 31.2295 9.1717C31.1864 9.52616 31.1358 9.89073 30.9535 10.1996C30.7712 10.506 30.475 10.7262 30.1509 10.8756C29.7813 11.0452 29.2218 11.2832 28.8116 11.2301C28.242 11.1566 27.9787 10.4528 27.5862 10.1135" stroke={activeIndex === 2 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.1426 1.76876C23.9173 1.29785 24.8186 1.10037 25.7098 1.31557C26.4516 1.49533 27.12 1.91054 27.6871 2.41943" stroke={activeIndex === 2 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.2069 1.89526C19.683 0.290106 23.4933 0.872417 23.2731 3.41433C23.2655 5.81953 23.2731 8.22473 23.2731 10.6299C23.5035 13.8023 18.9007 13.8985 17.7031 11.5161" stroke={activeIndex === 2 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                ) : (props.payload.payload.score == 4) ? (
                    <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.6976 18.0721C23.9837 17.3809 24.3331 16.7176 24.3913 15.9581C24.4495 15.1985 24.1888 14.4289 23.5685 14.039C23.2191 13.8212 22.7988 13.7529 22.3912 13.7022C20.4696 13.4668 18.5277 13.5073 16.5934 13.6086C15.9908 13.6389 15.3503 13.6896 14.8693 14.0516C14.4237 14.3858 14.1882 14.9403 14.13 15.4922C14.0388 16.3429 14.2186 17.2619 14.768 17.9329C15.3832 18.6823 16.6567 18.5886 17.5529 18.6114C19.4569 18.6544 21.8519 19.0013 23.5837 18.0772" stroke={activeIndex === 3 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14.7856 13.8615C14.0413 13.1476 13.8539 12.0184 13.9375 10.988C14.021 9.95752 14.335 8.96 14.421 7.92956C14.5426 6.46112 14.1603 4.96737 13.3957 3.70907C12.902 2.89636 11.2107 0.663326 10.0334 1.04309C9.12453 1.33931 9.30683 2.76218 9.33214 3.46348C9.42835 6.2206 8.21562 8.87392 6.69402 11.1095C5.8965 12.2792 4.98759 13.3729 4.00019 14.3882C3.10647 15.3072 1.8102 16.0794 1.28358 17.2744C0.977237 17.9706 0.989897 18.758 1.00762 19.5201C1.12915 24.5634 1.7393 30.3207 7.73458 31.2043C10.0993 31.5537 13.0463 31.2144 15.4059 30.9486" stroke={activeIndex === 3 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M13.4554 23.2695C12.7035 24.4696 12.6528 26.7356 14.1719 27.409C14.7289 27.6546 15.3517 27.5862 15.9416 27.5837C16.6277 27.5837 17.3138 27.5812 17.9974 27.5786C19.3697 27.5761 20.7394 27.5736 22.1116 27.5685C22.3825 27.5685 22.6686 27.5685 22.9066 27.7052C23.1041 27.8166 23.2433 28.0065 23.3395 28.2116C23.5015 28.5559 23.5522 28.9483 23.4813 29.3205C23.3572 29.9788 22.8686 30.5256 22.2863 30.8548C21.704 31.1839 21.028 31.3105 20.3621 31.3763C19.0557 31.5004 17.7367 31.389 16.4328 31.2295C16.0783 31.1864 15.7138 31.1358 15.4049 30.9535C15.0985 30.7712 14.8783 30.475 14.7289 30.1509C14.5593 29.7813 14.3213 29.2218 14.3744 28.8116C14.4479 28.242 15.1517 27.9787 15.491 27.5862" stroke={activeIndex === 3 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.8362 23.1431C24.3071 23.9178 24.5046 24.8191 24.2894 25.7103C24.1097 26.4521 23.6944 27.1205 23.1855 27.6876" stroke={activeIndex === 3 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.7107 18.206C25.3159 19.682 24.7335 23.4923 22.1916 23.2721C19.7864 23.2645 17.3812 23.2721 14.976 23.2721C11.8037 23.5025 11.7075 18.8997 14.0899 17.7021" stroke={activeIndex === 3 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                ) : (props.payload.payload.score == 5) ? (
                    <svg width="21" height="34" viewBox="0 0 21 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.90441 19.6184C4.66648 20.3501 4.84624 21.3831 5.2969 22.2768C7.3122 26.0871 13.9075 25.0212 12.6442 20.1222C12.0745 18.1955 11.0998 17.5651 9.47688 17.3322C7.01852 16.8638 5.45134 18.8184 3.39806 19.4741C2.19039 19.6539 1.57264 18.869 2.01317 17.7221C3.46642 14.3877 7.49702 13.6105 10.7428 13.4155" stroke={activeIndex === 4 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.6694 14.1802C19.8998 18.3526 20.4644 23.5782 18.7023 28.2215C17.882 30.9457 15.4084 32.566 12.626 32.6673C9.02832 32.7964 7.27378 30.7482 4.91162 28.4392C2.86087 26.4366 1.01268 24.9453 1.00002 21.7933C0.997488 20.8793 1.23548 19.8539 2.01274 19.3754" stroke={activeIndex === 4 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10.2344 3.54143C10.5078 1.04003 12.994 -0.109406 14.2574 2.41479C15.5309 5.92638 16.4119 9.6633 17.1132 13.2989C17.3031 14.1218 17.5259 14.9218 17.7563 15.7041" stroke={activeIndex === 4 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15.1774 4.30603C16.0433 3.41484 17.2889 2.69075 18.365 3.72878C19.4663 4.79213 18.8713 6.58717 18.7827 7.90117C18.6536 9.86584 18.6637 11.8432 18.6713 13.8104C18.6738 14.5269 18.4232 15.2737 17.7978 15.6788C17.0003 16.1953 15.9851 15.9371 15.1749 15.5902C14.4533 15.2813 13.7368 14.937 13.0254 14.6129C12.4482 14.3496 11.8026 14.1446 11.324 13.7243C10.6911 13.1673 10.2658 12.1723 9.85561 11.4381C9.02772 9.95192 8.28843 8.42018 7.44281 6.94415C6.97696 6.13398 6.50859 5.32887 6.72379 4.3592C6.89848 3.56675 7.49092 2.97937 8.33654 3.02494C9.19481 3.15913 9.99739 3.22242 10.5063 4.15918C12.557 7.7923 13.9723 12.3141 16.1952 15.8358" stroke={activeIndex === 4 ? 'white' : '#01195D'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                ) : ("")}
            </svg>
        );
    };

    const needle = (value: any, data: any, cx: any, cy: any, iR: any, oR: any, color: any) => {
        let total = 0;
        data.forEach((v: any) => {
            total += v.value;
        });
        const ang = 180.0 * (1 - value / total);
        const length = (iR + 4 * oR) / 3;
        const sin = Math.sin(-RADIAN * ang);
        const cos = Math.cos(-RADIAN * ang);
        const r = 10;
        const x0 = cx + 5;
        const y0 = cy + 3;
        const xba = x0 + r * sin - 2.5;
        const yba = y0 - r * cos;
        const xbb = x0 - r * sin + 2.5;
        const ybb = y0 + r * cos;
        const xp = x0 + length * cos;
        const yp = y0 + length * sin;

        return [
            <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={"#99A3BE"} className="chart-needle d-none" />,
            <circle cx={"50%"} cy={"50%"} r={r} fill={"#FFFFFF"} stroke="#99A3BE" strokeWidth={5} />,
        ];
    };

    function handleAnimationEnd() {
        const chartElement = document.querySelector('.recharts-surface');
        if (chartElement) {
            const viewbox = chartElement.getAttribute('viewBox');
            console.log(viewbox);
            const [x, y, width, height] = viewbox!.split(' ').map(parseFloat);
            console.log(width)
            console.log(height);
            const needle = document.querySelector('.chart-needle');
            console.log(needle);
            needle?.setAttribute("transform", "translate(" + ((width / 2) - 5) + ", " + ((height / 2) - 5) + ")");
            needle?.classList.remove("d-none");
        } else {
            console.log('Chart SVG not found');
        }
    }

    const handleClick = (index: any) => {
        setActiveIndex(index);
        props.setSelectedScore(index + 1);
        if (index === 0) {
            setValue(-10);
        } else if (index === 1) {
            setValue(20)
        } else if (index === 2) {
            setValue(50)
        } else if (index === 3) {
            setValue(80)
        } else if (index === 4) {
            setValue(110)
        }
    };

    const handleCheckboxChange = (event: any, index: number, emotionId: any) => {
        if (event.target.checked) {
            props.addSelectedEmotion(emotionId)
        } else {
            props.removeSelectedEmotion(emotionId)
        }
    };

    return (
        <div className="daily-checkin">
            <div className="daily-checkin__welcome">
                <h2 className="daily-checkin__welcome__title">Goodmorning <HandWaveIcon /></h2>
                <h2 className="daily-checkin__welcome__name">{props.username},</h2>
                <p className="daily-checkin__welcome__text">Please give your evaluation of yesterday.</p>
            </div>
            <div className="daily-checkin__meter">
                <ResponsiveContainer height={400} >
                    <PieChart>
                        <Pie
                            data={data}
                            dataKey="value"

                            innerRadius={50}
                            outerRadius={60}
                            fill="#8884d8"
                            startAngle={225}
                            endAngle={-45}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                                //<Cell key={`cell-${index}`} fill={"url(#colorUv)"} />
                            ))}
                        </Pie>

                        <Pie
                            data={data}
                            dataKey="value"
                            startAngle={225}
                            endAngle={-45}
                            innerRadius={60}
                            outerRadius={150}
                            paddingAngle={4}
                            fill="white"
                            label={renderCustomizedLabel}
                            labelLine={false}
                            onAnimationEnd={handleAnimationEnd}
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`}
                                    fill={activeIndex === index ? '#9F72FE' : 'white'}
                                    onClick={() => handleClick(index)} />
                            ))}
                        </Pie>
                        {needle(value, data, cx, cy, 50, 60, '#d0d000')}
                    </PieChart>
                </ResponsiveContainer>



            </div>
            <div className="daily-checkin__emotions">
                <h3 className="daily-checkin__emotions__title"><SuitcaseIcon /> Which emotions did you encounter during the day?</h3>
                <ol className="daily-checkin__emotions__list">
                    {
                        (!sentiments) ? ("Getting emotions...") : (
                            sentiments.averages.map((sentiment: any, index: number) => (
                                <li className="daily-checkin__emotions__list__item" key={index}>
                                    {IconsEmotions[sentiment.emotionIcon] && React.createElement(IconsEmotions[sentiment.emotionIcon])}
                                    <label className="toggle" htmlFor={`uniqueID${index}`}>
                                        <input
                                            type="checkbox"
                                            className="toggle__input"
                                            id={`uniqueID${index}`}
                                            onChange={(event) => handleCheckboxChange(event, index, sentiment.emotionId)}
                                            checked={props.selectedEmotions.includes(sentiment.emotionId)}
                                        />
                                        <span className="toggle-track">
                                            <span className="toggle-indicator">
                                                <span className="checkMark">
                                                    <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                                                        <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                        </span>
                                        {sentiment.emotion}
                                    </label>
                                </li>
                            ))
                        )
                    }
                </ol>
            </div>
            <button className="daily-checkin__btn" onClick={props.onClickContinueBtn}>Continue</button>
        </div>
    )
}

export default DailyCheckin
