import { useEffect, useState } from 'react'
import { ReactComponent as InfoIcon } from '../../assets/icons/icon-info.svg';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Tooltip as MetricTooltip } from "react-tooltip";
import Dropdown from "../general/Dropdown";
import IconsMetrics from "../general/IconsMetrics";
import { ReactComponent as LineIcon } from "../../assets/icons/icon-line.svg";
import React from "react";

function WorkBehaviour(props: any) {
    const [user, setUser] = useState<any>(props.user)
    const [activeTab, setActiveTab] = useState(0);
    const [workPatterns, setWorkPatterns] = useState<any>();
    const [workPatternHistory, setWorkPatternHistory] = useState<any>();
    const [activeMetric, setActiveMetric] = useState(0);
    const [selectedValueDropdown, setSelectedValueDropdown] = useState<any>('week');
    console.log("active workpattern" + activeMetric);

    useEffect(() => {
        getWorkPattern();
        getWorkPatternHistory(activeMetric);
    }, [activeTab, activeMetric])

    const getWorkPattern = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/workPattern?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setWorkPatterns(data);
    }

    const getWorkPatternHistory = async (activeMetricIndex: number) => {
        try {
            const options = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + props.token,
                },
            };
            const response = await fetch(
                `https://mindy-api.azurewebsites.net/workPattern/${selectedValueDropdown}?userId=${user.userId}`,
                options
            );
            const responseData = await response.json();
            console.log(responseData);
            let averages;

            //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
            //console.log("active metric:" + JSON.stringify(activeMetric));

            averages = responseData.averages.map((item: any) => ({
                name: item.name,
                average: item.metrics[activeTab].metrics[activeMetricIndex].metricScore,
                globalAvg: item.metrics[activeTab].metrics[activeMetricIndex].globalAvg
            }));

            console.log("averages: " + JSON.stringify(averages));
            setWorkPatternHistory(averages);

        } catch (error) {
            console.error("Error fetching work pattern history:", error);
        }
    };

    const handleClickTab = (index: number) => {
        setActiveTab(index);
        setActiveMetric(0);
    };

    const handleClickMetric = (index: number) => {
        setActiveMetric(index);
        //getWorkPatternHistory(index);
    };

    const handleDropdownChange = async (event: any) => {
        console.log("dropdown change value: " + event.target.value);
        setSelectedValueDropdown(event.target.value);

        try {
            const options = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + props.token,
                },
            };

            if (event.target.value === "week") {
                const response = await fetch(
                    `https://mindy-api.azurewebsites.net/workPattern/week?userId=${user.userId}`,
                    options
                );
                const responseData = await response.json();
                console.log(responseData);
                let averages;

                //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
                //console.log("active metric:" + JSON.stringify(activeMetric));

                averages = responseData.averages.map((item: any) => ({
                    name: item.name,
                    average: item.metrics[activeTab].metrics[activeMetric].metricScore,
                    globalAvg: item.metrics[activeTab].metrics[activeMetric].globalAvg
                }));

                console.log("averages: " + JSON.stringify(averages));
                setWorkPatternHistory(averages);
            } else if (event.target.value === "month") {
                const response = await fetch(
                    `https://mindy-api.azurewebsites.net/workPattern/month?userId=${user.userId}`,
                    options
                );
                const responseData = await response.json();
                console.log(responseData);
                let averages;

                //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
                //console.log("active metric:" + JSON.stringify(activeMetric));

                averages = responseData.averages.map((item: any) => ({
                    name: item.name,
                    average: item.metrics[activeTab].metrics[activeMetric].metricScore,
                    globalAvg: item.metrics[activeTab].metrics[activeMetric].globalAvg
                }));

                console.log("averages: " + JSON.stringify(averages));
                setWorkPatternHistory(averages);
            } else if (event.target.value == "year") {
                const response = await fetch(
                    `https://mindy-api.azurewebsites.net/workPattern/year?userId=${user.userId}`,
                    options
                );
                const responseData = await response.json();
                console.log(responseData);
                let averages;

                //const activeMetric = workPatterns[activeTab].metrics[activeMetricIndex];
                //console.log("active metric:" + JSON.stringify(activeMetric));

                averages = responseData.averages.map((item: any) => ({
                    name: item.name,
                    average: item.metrics[activeTab].metrics[activeMetric].metricScore,
                    globalAvg: item.metrics[activeTab].metrics[activeMetric].globalAvg
                }));

                console.log("averages: " + JSON.stringify(averages));
                setWorkPatternHistory(averages);
            }
        } catch (error) {
            console.error("Error fetching work pattern history:", error);
        }
    }

    const customTooltip = (props: any) => {
        const { payload } = props;
    if (payload && payload.length) {
        const { name, average, globalAvg } = payload[0].payload;

        const formatTime = (timeInHours: number) => {
            if (timeInHours < 1) {
                const minutes = Math.round(timeInHours * 60);
                return `${minutes} min`;
            } else {
                return `${timeInHours} hrs`;
            }
        };

        return (
            <div style={{ backgroundColor: 'white', padding: 8 }}>
                <p>{name}: {formatTime(average)}</p>
                <p>Your Average: {formatTime(globalAvg)}</p>
            </div>
        );
    }
    return null;
    }

    const renderLegend = (props: any) => {
        const { payload } = props;

        console.log("payload legend :" + JSON.stringify(payload));

        return (
            <ul style={{ display: "flex", justifyContent: "center" }}>
                {
                    payload.map((entry: any, index: any) => {
                        console.log("Entry legend:" + JSON.stringify(entry));

                        if (entry.payload.legendType !== 'none') {
                            return (
                                <li key={`legend-${index}`} style={{ display: "flex", alignItems: "center", fontSize: 8, color: '#01195D', fontWeight: 700, gap: 8 }}><LineIcon />YOUR AVERAGE</li>
                            );
                        }

                    })
                }
            </ul>
        );
    }

    return (
        <div className="work-behaviour">
            <section className="work-behaviour__graph">
                <div className="work-behaviour__graph__header">
                    <h2 className="work-behaviour__graph__header__title">Your <span>work behaviour</span></h2>
                    <Dropdown selectedValue={selectedValueDropdown} onDropdownChange={handleDropdownChange} />
                </div>
                {(!user) ? ("loading...") : (
                    <ResponsiveContainer width="100%" height="100%" aspect={1.6}>
                        <ComposedChart data={workPatternHistory} >
                            <XAxis dataKey="name" />
                            <YAxis width={25}/>
                            <Tooltip content={customTooltip} />
                            <Legend content={renderLegend}/>
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />
                            {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                            <Bar dataKey="average" barSize={20} fill="#01195D" legendType="none" radius={[4, 4, 0, 0]} />
                            <Line type="monotone" dataKey="globalAvg" stroke="#01195D" label="average" legendType="line" />
                        </ComposedChart>
                    </ResponsiveContainer>
                )}

            </section>
            <section className="work-behaviour__work-patterns">
                <h2 className="work-behaviour__work-patterns__title">Work Patterns</h2>

                <ol className="work-behaviour__work-patterns__tabs">
                    {(!workPatterns) ? (
                        <li className="work-behaviour__work-patterns__tabs__title active">Loading...</li>
                    ) : (
                        workPatterns.map((category: any, index: number) => (
                            <li
                                key={index}
                                className={`work-behaviour__work-patterns__tabs__title ${activeTab === index ? "active" : ""
                                    }`}
                                onClick={() => handleClickTab(index)}
                            >
                                {category.categoryName}
                            </li>
                        ))
                    )}
                </ol>
                {(!workPatterns) ? ("Loading...") : (
                    <dl className="work-behaviour__work-patterns__tab-content">
                        {workPatterns[activeTab].metrics.map((metric: any, index: number) => (
                            <li
                                key={index}
                                className={`work-behaviour__work-patterns__tab-content__item ${activeMetric === index ? "active" : ""
                                    }`}
                                onClick={() => handleClickMetric(index)}
                            >
                                {IconsMetrics[metric.metricIcon] && React.createElement(IconsMetrics[metric.metricIcon])}
                                <p>{metric.name}</p>
                                <span>{metric.metricScore}{metric.metricUnit}</span>
                                <InfoIcon data-tooltip-id={"tooltip-metric-" + index}   onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}/>
                                <MetricTooltip id={"tooltip-metric-" + index} place="top-end" content={metric.tooltip}/>
                            </li>
                        ))}
                    </dl>
                )}

            </section>
        </div>
    )
}

export default WorkBehaviour