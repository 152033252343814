import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useState } from 'react'
import { ReactComponent as WelcomeWizardImage } from "../assets/img/img-welcome-wizard.svg";
import { ReactComponent as MindthrivenImage } from "../assets/img/img-mindthriven.svg";
import { toast } from "react-toastify";


function WelcomeWizard(props: any) {
    const navigate = useNavigate();

    async function createAccount() {
        var user = {};

        var options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
            body: JSON.stringify(user)
        };

        try {
            const response = await fetch('https://mindy-api.azurewebsites.net/Employee', options);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const resJson = await response.json();
            console.log(resJson);
            navigate("/welcome-wizard/introduction");

        } catch (error) {
            toast.error('An error occurred:' + error);
            console.error('An error occurred:', error);
        }
    }


    return (
        <main className="welcome-wizard">
            <section className="welcome-wizard__container">
                <h2 className="welcome-wizard__container__title">Welcome!</h2>
                <WelcomeWizardImage />
                <MindthrivenImage style={{ marginTop: 44 }} />
                <button className="welcome-wizard__container__btn" onClick={createAccount}>Let's start your mindful journey now!</button>
                <p className="welcome-wizard__container__text">This will only take a couple of minutes..</p>
            </section>
        </main>
    )
}

export default WelcomeWizard
