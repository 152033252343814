import { ReactComponent as IconFocusWork } from '../../assets/icons/metrics/icon-focus-work.svg';
import { ReactComponent as Icon1v1Convo } from '../../assets/icons/metrics/icon-1v1-convo.svg';
import { ReactComponent as Icon1vManagerConvo } from '../../assets/icons/metrics/icon-1vmanager-convo.svg';
import { ReactComponent as IconInteraction } from '../../assets/icons/metrics/icon-interaction.svg';
import { ReactComponent as IconUninterrupted } from '../../assets/icons/metrics/icon-uninterrupted.svg';
import { ReactComponent as IconConflicting } from '../../assets/icons/metrics/icon-conflicting.svg';
import { ReactComponent as IconSkip } from '../../assets/icons/metrics/icon-skip.svg';
import { ReactComponent as IconMultitasking } from '../../assets/icons/metrics/icon-multitasking.svg';
import { ReactComponent as IconBusWorking } from '../../assets/icons/metrics/icon-bus-working.svg';
import { ReactComponent as IconBusMeeting } from '../../assets/icons/metrics/icon-bus-meeting.svg';
import { ReactComponent as IconBusEmails } from '../../assets/icons/metrics/icon-bus-emails.svg';
import { ReactComponent as IconBusMessages } from '../../assets/icons/metrics/icon-bus-messages.svg';
import { ReactComponent as IconBusScheduledCalls } from '../../assets/icons/metrics/icon-bus-scheduled-calls.svg';
import { ReactComponent as IconBusAdhocCalls } from '../../assets/icons/metrics/icon-bus-adhoc-calls.svg';
import { ReactComponent as IconAfterCollaboration } from '../../assets/icons/metrics/icon-after-collaboration.svg';
import { ReactComponent as IconAfterMeetings } from '../../assets/icons/metrics/icon-after-meetings.svg';
import { ReactComponent as IconAfterEmails } from '../../assets/icons/metrics/icon-after-emails.svg';
import { ReactComponent as IconAfterMessages } from '../../assets/icons/metrics/icon-after-messages.svg';
import { ReactComponent as IconAfterScheduledCalls } from '../../assets/icons/metrics/icon-after-scheduled-calls.svg';
import { ReactComponent as IconAfterAdhocCalls } from '../../assets/icons/metrics/icon-after-adhoc-calls.svg';

const IconsMetrics: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    'icon-focus-work.svg': IconFocusWork,
    'icon-1v1-convo.svg': Icon1v1Convo,
    'icon-1vmanager-convo.svg': Icon1vManagerConvo,
    'icon-interaction.svg': IconInteraction,
    'icon-uninterrupted.svg': IconUninterrupted,
    'icon-conflicting.svg': IconConflicting,
    'icon-skip.svg': IconSkip,
    'icon-multitasking.svg': IconMultitasking,
    'icon-bus-working.svg': IconBusWorking,
    'icon-bus-meeting.svg': IconBusMeeting,
    'icon-bus-emails.svg': IconBusEmails,
    'icon-bus-messages.svg': IconBusMessages,
    'icon-bus-scheduled-calls.svg': IconBusScheduledCalls,
    'icon-bus-adhoc-calls.svg': IconBusAdhocCalls,
    'icon-after-collaboration.svg': IconAfterCollaboration,
    'icon-after-meetings.svg': IconAfterMeetings,
    'icon-after-emails.svg': IconAfterEmails,
    'icon-after-messages.svg': IconAfterMessages,
    'icon-after-scheduled-calls.svg': IconAfterScheduledCalls,
    'icon-after-adhoc-calls.svg': IconAfterAdhocCalls,
    default: IconFocusWork,
    null:  IconFocusWork
};

export default IconsMetrics;