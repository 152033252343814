import { useState, useEffect } from 'react'
import { ReactComponent as StressedIcon } from '../../assets/icons/icon-stressed.svg';


function Dropdown(props: any) {
    console.log("props dropdown" + JSON.stringify(props));

    return (
        <select className={props.type === 'reverse' ? 'dropdown-inverse' : 'dropdown'} value={props.selectedValue} onChange={props.onDropdownChange}>
            <option value={"week"}>Past 7 days</option>
            <option value={"month"}>Past 4 weeks</option>
            <option value={"year"}>Past 12 months</option>
        </select>
    )
}

export default Dropdown;