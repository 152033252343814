import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as StartQuestIcon } from "../../assets/icons/advices/icon-start-quest.svg";


// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import { SurveyListItem } from './SurveyListItem';
import IconsAdvices from '../general/IconsAdvices';
import { toast } from 'react-toastify';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    advice: any;
    questSurvey: any;
    token: string;
    refreshAdvices: () => void;
    finish: boolean;
    setQuestResults: (data: any) => void;
    toggleQuestResultsModal: () => void;
}

export const ModalQuestSurvey: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    advice,
    questSurvey,
    token,
    refreshAdvices,
    finish,
    setQuestResults,
    toggleQuestResultsModal,
}) => {

    console.log("got advice: " + JSON.stringify(advice));


    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
    console.log("selected answer: " + selectedAnswer)
    const handleAnswerChange = (score: number) => {
        console.log("clicked answer with score " + score);
        setSelectedAnswer(score);
    };

    const answerSurvey = async () => {
        try {
            var options = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({ 
                    score: selectedAnswer, 
                    adviceId: advice.id
                })
            }

            let response;
            if(finish){
                response = await fetch("https://mindy-api.azurewebsites.net/Quest/Finish", options);
            }else{
                response = await fetch("https://mindy-api.azurewebsites.net/Quest/Start", options);
            }

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }

            if(finish){
                const data = await response.json();
                setQuestResults(data.questResult);
                toggleQuestResultsModal();
            }else{
                toast.success("Thank you for answering this survey, your quest has started.");
            }

            refreshAdvices();
            hide();
            
        } catch (error) {
            // Handle the error here
            console.error(error);
            toast.error("Quest could not start, please contact us.");
        }
    }

    const modalQuestSurvey = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    {(!advice) ? ("No Advice") : (
                        <div className="modal-quest">
                            <div className='modal-quest__header' style={{ ["--metrics-color" as any]: advice.metricColor }}>
                                {IconsAdvices[advice.categoryIcon] && React.createElement(IconsAdvices[advice.categoryIcon])}
                                <div className='modal-quest__header__info'>
                                    <h2>{advice.metricCategory}</h2>
                                    <p>{advice.metricAdvice}</p>
                                </div>

                                <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                            </div>
                            <div className='modal-quest__body'>
                                {(!questSurvey) ? ("No Survey") : (
                                    questSurvey.questions.map((question: any, index: number) => (
                                        <div key={question.id} className='modal-quest__body__question'>
                                            <p><span style={{ ["--metrics-color" as any]: advice.metricColor }}>{index + 1}.</span> {question.question}</p>
                                            {(!question.answers) ? ("No answers") : (
                                                <div className='modal-quest__body__question__answers'>
                                                    {question.answers.map((answer: any) => {
                                                        console.log("mapping over answer: " + JSON.stringify(answer) + " with selectedAnswer being :" + selectedAnswer);
                                                        return (
                                                            <div key={answer.id}>
                                                                <input type="radio" id={"a1q" + answer.id} name={"question" + question.id} checked={selectedAnswer === answer.score} value={answer.score} onChange={() => handleAnswerChange(answer.score)} />
                                                                <label htmlFor={"a1q" + answer.id}>{answer.description}</label>
                                                            </div>
                                                        )

                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    )))}

                            </div>
                            <div className='modal-quest__footer'>
                                <button onClick={answerSurvey} style={{ ["--metrics-color" as any]: advice.metricColor }}><StartQuestIcon /> {(finish)?("Finish quest!"):("Start quest!")}</button>
                            </div>
                        </div>
                    )}
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalQuestSurvey, rootContainer) : null;
};