import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import AsyncSelect from 'react-select/async';
import { ReactComponent as IconList } from "../../assets/icons/icon_list.svg";


import { ReactComponent as IconEntity } from "../../assets/icons/filter/icon-entity.svg";
import { ReactComponent as IconJobTitle } from "../../assets/icons/filter/icon-job-title.svg";
import { ReactComponent as IconAgeSegment } from "../../assets/icons/filter/icon-age-segment.svg";
import { ReactComponent as IconDepartment } from "../../assets/icons/filter/icon-department.svg";
import { ReactComponent as IconCountry } from "../../assets/icons/filter/icon-country.svg";
import { ReactComponent as IconCareer } from "../../assets/icons/filter/icon-career.svg";

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconReset } from "../../assets/icons/icon-reset.svg";
import { ReactComponent as IconSave } from "../../assets/icons/icon-save.svg";

import { toast } from 'react-toastify';

// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import Dropdown from './Dropdown';
import DropdownFilter from './DropdownFilter';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    onFilterChange: (event: any) => void;
    token: string;
    filters: any;
}

export const ModalFilter: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    onFilterChange,
    token,
    filters
}) => {
    console.log("got filters in filter modal " + JSON.stringify(filters));
    type Option = { value: string; label: string };
    const [validQuery, setValidQuery] = useState<any>(false);

    const [selectedView, setSelectedView] = useState<any>(filters.view);
    const [selectedDepartments, setSelectedDepartments] = useState<any>();
    const [selectedEntities, setSelectedEntities] = useState<any>();
    const [selectedJobTitles, setSelectedJobTitles] = useState<any>();
    const [selectedCountries, setSelectedCountries] = useState<any>();

    const [departments, setDepartments] = useState<any>();
    const [entities, setEntities] = useState<any>();
    const [jobTitles, setJobTitles] = useState<any>();
    const [countries, setCountries] = useState<any>();
    const [employeeCount, setEmployeeCount] = useState<any>();

    useEffect(() => {
        const queryString = `?departments=${filters.departments}&entities=${filters.entities}&jobTitles=${filters.jobTitles}&countries=${filters.countries}`;
        getCompany(queryString, true);
    }, [])

    const getCompany = async (queryString: string, isFromUseEffect = false) => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/Company" + queryString, options);
        const data = await response.json();

        console.log(response);
        console.log(data);

        setValidQuery(data.validQuery);

        setEmployeeCount(data.employeeCount);
        setDepartments(data.departments);
        setJobTitles(data.jobTitles);
        setEntities(data.entities);
        setCountries(data.countries);

        if (isFromUseEffect) {
            setSelectedDepartments(
                filters.departments.map((departmentId: string) => ({
                    value: departmentId,
                    label: getDepartmentName(departmentId, data.departments),
                })) || []
            );

            setSelectedJobTitles(
                filters.jobTitles.map((jobTitleId: string) => ({
                    value: jobTitleId,
                    label: getJobTitleName(jobTitleId, data.jobTitles),
                })) || []
            );

            setSelectedEntities(
                filters.entities.map((entityId: string) => ({
                    value: entityId,
                    label: getEntityName(entityId, data.entities),
                })) || []
            );

            setSelectedCountries(
                filters.countries.map((countryId: string) => ({
                    value: countryId,
                    label: getCountryName(countryId, data.countries),
                })) || []
            );
        }
    }

    const handleViewChange = async (event: any) => {
        console.log("dropdown view change value: " + event.target.value);
        setSelectedView(event.target.value);

        // const selectedFilters = {
        //     ...filters,
        //     view: [event.target.value],
        // };

        //onFilterChange(selectedFilters);

        //hide();
    }

    // const handleDepartmentChange = async (event: any) => {
    //     console.log("Department dropdown change value: " + event.target.value);

    //     const selectedFilters = {
    //         ...filters,
    //         departments: [event.target.value],
    //     };

    //     onFilterChange(selectedFilters);
    // };

    const filterDepartments = (inputValue: string) => {
        const filteredData = departments.filter((i: any) => i.name.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('Filtered data:', filteredData);

        return filteredData.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
        }));
    };


    const promiseOptionsDepartments = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(filterDepartments(inputValue));
            }, 5000);
        });

    const onDepartmentsChange = (selectedOptions: readonly Option[]) => {
        setSelectedDepartments([...selectedOptions]);

        const selectedFilters = {
            ...filters,
            departments: selectedOptions.map((option) => option.value),
        };

        const newQueryString = `?departments=${selectedFilters.departments}&entities=${selectedFilters.entities}&jobTitles=${selectedFilters.jobTitles}&countries=${selectedFilters.countries}`;
        getCompany(newQueryString);
        //onFilterChange(selectedFilters);
    };

    const filterJobTitles = (inputValue: string) => {
        const filteredData = jobTitles.filter((i: any) => i.name.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('Filtered data:', filteredData);

        return filteredData.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
        }));
    };


    const promiseOptionsJobTitles = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(filterJobTitles(inputValue));
            }, 5000);
        });

    const onJobTitlesChange = (selectedOptions: readonly Option[]) => {
        setSelectedJobTitles(selectedOptions);
        //getCompany(queryStringRef.current);

        const selectedFilters = {
            ...filters,
            jobTitles: selectedOptions.map((option) => option.value),
        };

        const newQueryString = `?departments=${selectedFilters.departments}&entities=${selectedFilters.entities}&jobTitles=${selectedFilters.jobTitles}&countries=${selectedFilters.countries}`;
        getCompany(newQueryString);

        //onFilterChange(selectedFilters);
    };

    const filterEntities = (inputValue: string) => {
        const filteredData = entities.filter((i: any) => i.name.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('Filtered data:', filteredData);

        return filteredData.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
        }));
    };


    const promiseOptionsEntities = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(filterEntities(inputValue));
            }, 5000);
        });

    const onEntitiesChange = (selectedOptions: readonly Option[]) => {
        setSelectedEntities(selectedOptions);

        const selectedFilters = {
            ...filters,
            entities: selectedOptions.map((option) => option.value),
        };

        const newQueryString = `?departments=${selectedFilters.departments}&entities=${selectedFilters.entities}&jobTitles=${selectedFilters.jobTitles}&countries=${selectedFilters.countries}`;
        getCompany(newQueryString);

        //onFilterChange(selectedFilters);
    };

    const filterCountires = (inputValue: string) => {
        const filteredData = countries.filter((i: any) => i.name.toLowerCase().includes(inputValue.toLowerCase()));
        console.log('Filtered data:', filteredData);

        return filteredData.map((item: any) => ({
            label: item.name,
            value: item.id.toString()
        }));
    };


    const promiseOptionsCountries = (inputValue: string) =>
        new Promise<any>((resolve) => {
            setTimeout(() => {
                resolve(filterCountires(inputValue));
            }, 5000);
        });

    const onCountriesChange = (selectedOptions: readonly Option[]) => {
        setSelectedCountries(selectedOptions);

        const selectedFilters = {
            ...filters,
            countries: selectedOptions.map((option) => option.value),
        };

        const newQueryString = `?departments=${selectedFilters.departments}&entities=${selectedFilters.entities}&jobTitles=${selectedFilters.jobTitles}&countries=${selectedFilters.countries}`;
        getCompany(newQueryString);

        //onFilterChange(selectedFilters);
    };

    const getDepartmentName = (departmentId: string, departments: any) => {
        console.log("getting departments by name with departments: " + departments);
        if (departments) {
            const department = departments.find((d: any) => d.id.toString() === departmentId);
            return department ? department.name : '';
        }
        return '';
    };

    const getJobTitleName = (jobTitleId: string, jobTitles: any) => {
        console.log("getting jobtitles by name with jobtitles: " + jobTitles);
        if (jobTitles) {
            const jobTitle = jobTitles.find((d: any) => d.id.toString() === jobTitleId);
            return jobTitle ? jobTitle.name : '';
        }
        return '';
    };

    const getEntityName = (entityId: string, entities: any) => {
        console.log("getting entities by name with entities: " + entities);
        if (entities) {
            const entity = entities.find((d: any) => d.id.toString() === entityId);
            return entity ? entity.name : '';
        }
        return '';
    };

    const getCountryName = (countryId: string, countries: any) => {
        console.log("getting countries by name with countries: " + countries);
        if (countries) {
            const country = countries.find((d: any) => d.id.toString() === countryId);
            return country ? country.name : '';
        }
        return '';
    };

    const resetFilters = () => {

        setSelectedView('week');
        setSelectedDepartments([]);
        setSelectedEntities([]);
        setSelectedJobTitles([]);
        setSelectedCountries([]);

        const resetFilterValues = {
            view: 'week',
            departments: [],
            entities: [],
            jobTitles: [],
            countries: [],
        };

        onFilterChange(resetFilterValues);
    };

    const onSaveFilter = () => {
        const selectedFilters = {
            view: selectedView,
            departments: selectedDepartments ? selectedDepartments.map((option: any) => option.value) : [],
            entities: selectedEntities ? selectedEntities.map((option: any) => option.value) : [],
            jobTitles: selectedJobTitles ? selectedJobTitles.map((option: any) => option.value) : [],
            countries: selectedCountries ? selectedCountries.map((option: any) => option.value) : [],
        };

        onFilterChange(selectedFilters);
        hide();
    };

    const modalFilter = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-filter">
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        <div className='modal-filter__header'>
                            <h2 className='modal-filter__header__title'>Choose your filters.<span>({employeeCount !== undefined && `${employeeCount} ${employeeCount !== 1 ? 'employees' : 'employee'}`})</span></h2>
                            <Dropdown selectedValue={selectedView} onDropdownChange={handleViewChange} type="reverse" />
                        </div>
                        <div className='modal-filter__body'>
                            {!validQuery && <p className='error-msg'>Please select another filter to keep your data anonimous.</p>}
                            <ul className='filter-list'>
                                {(!entities || entities.length === 0 || entities.length === 1) ? ("") : (
                                    <li className='filter'>
                                        <label><IconEntity />Corporate's entity</label>
                                        {/* <DropdownFilter data={entities} /> */}
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={promiseOptionsEntities}
                                            onChange={onEntitiesChange}
                                            value={selectedEntities}
                                        />
                                    </li>
                                )}
                                {(!jobTitles || jobTitles.length === 0 || jobTitles.length === 1) ? ("") : (
                                    <li className='filter'>
                                        <label><IconJobTitle /> Job title</label>
                                        {/* <DropdownFilter data={jobTitles} /> */}
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={promiseOptionsJobTitles}
                                            onChange={onJobTitlesChange}
                                            value={selectedJobTitles}
                                        />
                                    </li>
                                )}

                                {/* <li className='filter'>
                                    <label><IconAgeSegment /> Age Segment (All)<span>Reset</span></label>
                                    <DropdownFilter />
                                </li> */}
                                {(!departments || departments.length === 0 || departments.length === 1) ? ("") : (
                                    <li className='filter'>
                                        <label><IconDepartment />Department</label>
                                        {/* <DropdownFilter data={departments} /> */}
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={promiseOptionsDepartments}
                                            onChange={onDepartmentsChange}
                                            value={selectedDepartments}
                                        />
                                    </li>
                                )}
                                {(!countries || countries.length === 0 || countries.length === 1) ? ("") : (
                                    <li className='filter'>
                                        <label><IconCountry />Country (employees)</label>
                                        {/* <DropdownFilter data={countries} /> */}
                                        <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={promiseOptionsCountries}
                                            onChange={onCountriesChange}
                                            value={selectedCountries}
                                        />
                                    </li>
                                )}

                                {/* <li className='filter'>
                                    <label><IconCareer />Career segment (years)<span>Reset</span></label>
                                    <DropdownFilter />
                                </li> */}
                            </ul>
                            {/* <div>
                                <input type='checkbox'/>
                                <label>Employees in danger</label>
                            </div> */}

                            <div className='modal-filter__body__btn-grp'>
                                <button className='btn modal-filter__body__btn-grp__reset' onClick={resetFilters}><IconReset /> Reset</button>
                                <button className='btn modal-filter__body__btn-grp__save' onClick={onSaveFilter} disabled={!validQuery}><IconSave /> Save</button>
                            </div>
                        </div>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalFilter, rootContainer) : null;
};