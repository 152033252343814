import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";


import {
    Wrapper,
    StyledModal,
    Backdrop,
} from './modal.style';
import VideoPlayer from './VideoPlayer';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
}

export const ModalVideoPlayer: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
}) => {
   


    const modalVideoPlayer = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-video">
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        <VideoPlayer/>
                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );
    
    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalVideoPlayer, rootContainer) : null;
};