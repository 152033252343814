import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconList } from "../../assets/icons/icon_list.svg";
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconSteps } from "../../assets/icons/icon_steps_small.svg";
import { ReactComponent as IconRemove } from "../../assets/icons/icon_remove_small.svg";
import { ReactComponent as IconShield } from "../../assets/icons/icon_shield_small.svg";
import { ReactComponent as IconTime } from "../../assets/icons/icon_time_small.svg";

import { ReactComponent as UserEngagementSurvey } from "../../assets/img/illustration_user_engagement_survey.svg";

import {
  Wrapper,
  Header,
  StyledModal,
  HeaderText,
  CloseButton,
  // Content,
  Backdrop,
} from '../general/modal.style';
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  token: string;
  hrSurveyId: number;
}

export const Modal: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  token,
  hrSurveyId
}) => {
  const [surveyDetails, setSurveyDetails] = useState<any>();
  const [engagementData, setEngagementData] = useState<any>();
  console.log("engagement data: " + JSON.stringify(engagementData ));  
  useEffect(() => {
    getSurveyDetails();
  }, [])

  const getSurveyDetails = async () => {
    var options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
    }

    const response = await fetch("https://mindy-api.azurewebsites.net/Survey/stats?surveyId=" + hrSurveyId, options);
    const data = await response.json();

    console.log(response);
    console.log(data);
    setSurveyDetails(data);
    setEngagementData({
      "name": "engagement",
      "peopleAnswered": data.completedEmployees,
      "peopleQuestioned": data.totalEmployees,
      "differencePeople": data.totalEmployees - data.completed
    })
  }

  const modal = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          <div className="model-hr__details-survey">
            <button className="btn-close-modal no-bg" onClick={hide}><IconClose /></button>

            <div className="left">
              <h1 className="left__title | heading-l txt-primary">{(!surveyDetails) ? ("...") : (surveyDetails.title)}</h1>
              <p className='left__date'>{(!surveyDetails) ? ("...") : (surveyDetails.date)}</p>
              <div className="departments">
                <h3 className="departments__title | body-2 txt-upper txt-primary">Departments<span>({(!surveyDetails) ? ("...") : (surveyDetails.departments.length)})</span></h3>
                <ul className="departments__list">
                  {(!surveyDetails) ? ("loading...") : (
                    surveyDetails.departments.map((item: any) => {
                      return (
                        <li className="departments__item">
                          <p className="body-3">{item}</p>
                        </li>
                      )
                    })
                  )}

                  {/* <li className="departments__item">
                    <p className="body-3">Marketing</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">IT</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Sales</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Finance</p>
                  </li>
                  <li className="departments__item">
                    <p className="body-3">Customer support</p>
                  </li> */}
                </ul>
              </div>
              <div className="user-engagement">
                <h3 className="user-engagement__title | body-2 txt-upper txt-primary">User engagement <span>({(!surveyDetails) ? ("...") : (surveyDetails.totalEmployees)} People questioned)</span></h3>
                {(!engagementData) ? ("...") : (
                  <BarChart
                    width={500}
                    height={500}
                    data={engagementData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                    layout="vertical"
                    barGap={-85}
                  >
                    <XAxis type="number" hide />
                    <YAxis dataKey="name" type="category" hide />
                    {/* <Tooltip />
                  <Legend /> */}
                    <Bar dataKey="peopleAnswered" fill="#82ca9d" barSize={85} radius={[54, 54, 54, 54]}/>
                    <Bar dataKey="peopleQuestioned" fill="#8884d8" barSize={85} radius={[54, 54, 54, 54]} />
                  </BarChart>
                )}

              </div>
            </div>

            <div className="right">
              <div className="header">
                <h3 className="header__title | txt-primary"><IconList /> Results</h3>
                {/* TODO: add dropdown */}
              </div>
              <div className="content">
                <ul className="content__list">
                  {(!surveyDetails) ? ("loading...") : (
                    surveyDetails.topics.map((item: any) => {
                      return (
                        <li className="content__item">
                          <div>
                            <IconShield className="icon bg-hr" />
                            <div className="text">
                              <p className="body-1 txt-hr">{item.name}</p>
                              {/* <p>Latest data: 22/01 | 10:45pm</p> */}
                            </div>
                            <p className="score | body-2 txt-primary">{item.score}/5</p>
                          </div>
                        </li>
                      )
                    })
                  )}
                  {/* <li className="content__item">
                    <div>
                      <IconSteps className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Work pressure</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">3.8/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconRemove className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Work-Life conflict</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">4.5/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconShield className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Physical strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">3.5/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconTime className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Mental strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">4/5</p>
                    </div>
                  </li>
                  <li className="content__item">
                    <div>
                      <IconSteps className="icon bg-hr" />
                      <div className="text">
                        <p className="body-1 txt-hr">Emotional strain</p>
                        <p>Latest data: 22/01 | 10:45pm</p>
                      </div>
                      <p className="score | body-2 txt-primary">2.4/5</p>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );
  
  const rootContainer = document.getElementById('root');
  return isShown && rootContainer ? ReactDOM.createPortal(modal, rootContainer) : null;
};