import { ReactComponent as IconSustainableWork } from '../../assets/icons/advices/icon-sustainable-work.svg';
import { ReactComponent as IconTimeManagement } from '../../assets/icons/advices/icon-time-management.svg';
import { ReactComponent as IconWorkLife } from '../../assets/icons/advices/icon-work-life.svg';
import { ReactComponent as IconInteractionCoWorkers } from '../../assets/icons/advices/icon-interaction-co-workers.svg';


const IconsAdvices: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
    'icon-sustainable-work.svg': IconSustainableWork,
    'icon-time-management.svg': IconTimeManagement,
    'icon-work-life.svg': IconWorkLife,
    'icon-interaction-co-workers.svg': IconInteractionCoWorkers,
    default: IconSustainableWork,
    null:  IconSustainableWork
};

export default IconsAdvices;