import { NavLink, Link, useLocation } from "react-router-dom";

import { ReactComponent as IconDashboard } from "../../assets/icons/icon_dashboard.svg";
import { ReactComponent as IconDashboardEmpty } from "../../assets/icons/icon_dashboard_empty.svg";
import { ReactComponent as IconHR } from "../../assets/icons/icon_hr.svg";
import { ReactComponent as IconMindfulMorning } from "../../assets/icons/icon-mindful-morning.svg";
import { ReactComponent as IconFilterOutline } from "../../assets/icons/icon-filter-outline-small.svg";
import { ReactComponent as IconProfile } from "../../assets/icons/icon-profile.svg";
import { ReactComponent as IconFilterFull } from "../../assets/icons/icon-filter-full.svg";
import { ReactComponent as IconFilterDown } from "../../assets/icons/icon-filter-down.svg";
import { useModal } from "./useModal";
import { ModalFilter } from "./ModalFilter";
import { ModalProfile } from "./ModalProfile";
import { useState } from "react";


export function Navigation(props: any) {
  let currentRoute = useLocation();
  //   console.log("props profile: " + JSON.stringify(props));
  var hour = (new Date()).getHours();
  const { isShown: isShownFilterModal, toggle: toggleFilterModal } = useModal();
  const { isShown: isShownProfileModal, toggle: toggleProfileModal } = useModal();
  const [countFilterSelected, setCountFilterSelected] = useState(0);

  function onFilterChange(filters: any) {
    console.log("navigation got filters: " + JSON.stringify(filters) + " with length: " + filters.length);
    let newCountFilterSelected = 0;

    for (const filter in filters) {
      console.log("checking filter: " + JSON.stringify(filter));
      if (Array.isArray(filters[filter]) && filters[filter].length > 0) {
        newCountFilterSelected++;
      }
    }

    console.log("Number of list filters with length > 0: " + newCountFilterSelected);
    setCountFilterSelected(newCountFilterSelected);
    props.onFilterChange(filters);
  }

  // Mobile navigation
  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled(!isToggled);


  // currentRoute.pathname = 'hr';

  return (
    <header className={(currentRoute.pathname === '/mindful-morning' || currentRoute.pathname === '/welcome-wizard' || currentRoute.pathname === '/welcome-wizard/introduction' || currentRoute.pathname === "/welcome-wizard/survey" || currentRoute.pathname === "/welcome-wizard/preferences") ? ("d-none") : ("header-main")} >

      <nav className={(currentRoute.pathname === '/hr') ? ("nav container bg-white nav-hr") : ("nav container bg-white ")} aria-label="Main">
        <ul className="nav__list">
          <li>
            {currentRoute.pathname === '/' ? (
              <p className="nav__hello-text | heading-m"><IconDashboard />{(hour < 12) ? ("Good morning") : (hour > 12 && hour < 18) ? ("Good afternoon") : ("Good evening")}, {props.username}!</p>
            ) : (
              <Link to="/" className="nav__go-back">
                <IconDashboardEmpty />
                <p className="body-1">Home</p>
              </Link>
            )}
          </li>
          <button
            type="submit"
            id="menu-toggle"
            className="nav__menu-toggle"
            aria-expanded={isToggled}
            aria-label="toggle menu"
            onClick={toggle}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          {props.user && (
            <>
              {currentRoute.pathname === '/hr' ? (
                <>
                  <li className="nav__item--centered hr-subtitle">
                    <p className="nav__item--centered__title">HR Wellbeing</p>
                    <p className="nav__item--centered__subtitle"><IconFilterOutline />{(countFilterSelected === 0) ? (" No filter selected") : (countFilterSelected + " filter(s) selected")}</p>
                  </li>
                  <div className={`nav__nav-list ${isToggled ? 'shown shown--hr' : ''}`}>
                    <li className="nav__item" >
                      <button className="nav__item__filter" onClick={toggleFilterModal}><IconFilterFull /> Filter <span>({countFilterSelected})</span> <IconFilterDown /></button>
                      <ModalFilter isShown={isShownFilterModal} hide={toggleFilterModal} onFilterChange={onFilterChange} token={props.token} filters={props.filters} />
                    </li>
                    {
                      (props.user.mindfulMorningCompleted) ? ("") : (
                        <li className="nav__item" >
                          <NavLink to="/mindful-morning" className={"nav__link"} title="Mindful Morning">
                            <IconMindfulMorning />
                            <span className="sr-only">Mindful Morning</span>
                          </NavLink>
                        </li>
                      )
                    }
                    {(props.user.isHR) ? (
                      <li className="nav__item" >
                        <NavLink to="/hr" className={(currentRoute.pathname === "/hr") ? ("nav__link active") : ("nav__link")} title="HR platform">
                          <IconHR />
                          <span className="sr-only">HR platform</span>
                        </NavLink>
                      </li>
                    ) : ("")}
                    <li className="nav__item" >
                      <div className={(isShownProfileModal) ? ("nav__link active") : ("nav__link")} onClick={toggleProfileModal} title="Profile">
                        <IconProfile />
                        <span className="sr-only">Profile</span>
                      </div>
                      <ModalProfile isShown={isShownProfileModal} hide={toggleProfileModal} fullName={props.fullName} profilePicture={props.profilePicture} token={props.token} />
                    </li>

                  </div>
                </>
              ) : (
                <div className={`nav__nav-list ${isToggled ? 'shown' : ''}`}>
                  {
                    (props.user.mindfulMorningCompleted) ? ("") : (
                      <li className="nav__item" >
                        <NavLink to="/mindful-morning" className={"nav__link"} title="Mindful Morning">
                          <IconMindfulMorning />
                          <span className="sr-only">Mindful Morning</span>
                        </NavLink>
                      </li>
                    )
                  }
                  {(props.user.isHR) ? (
                    <li className="nav__item" >
                      <NavLink to="/hr" className={(currentRoute.pathname === "/hr") ? ("nav__link active") : ("nav__link")} title="HR platform"
                        onClick={() =>
                          window.matchMedia("(max-width: 767px)").matches && toggle()
                        }
                      >
                        <IconHR />
                        <span className="sr-only">HR platform</span>
                      </NavLink>
                    </li>
                  ) : ("")}
                  <li className="nav__item" >
                    <div className={(isShownProfileModal) ? ("nav__link active") : ("nav__link")} onClick={()=>{toggleProfileModal(); window.matchMedia("(max-width: 767px)").matches && toggle()}} title="Profile">
                      <IconProfile />
                      <span className="sr-only">Profile</span>
                    </div>
                    <ModalProfile isShown={isShownProfileModal} hide={toggleProfileModal} fullName={props.fullName} profilePicture={props.profilePicture} token={props.token} />
                  </li>
                </div>
              )}
            </>
          )}



          {/* <li className="nav__item" style={{visibility:'hidden'}}>
              <NavLink to="/hr-platform" className="nav__link">
                <IconHR />
                <span className="sr-only">HR platform</span>
              </NavLink>
            </li> */}
          {/* <li className="nav__item">
            <NavLink to="/marketplace" className="nav__link" activeClassName="active">
              <IconMarketplace />
              <span className="sr-only">Marketplace</span>
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </header>
  );
}