import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconList } from "../../assets/icons/icon_list.svg";


import { ReactComponent as IconCalendar } from "../../../assets/icon_calendar_survey.svg";
import { ReactComponent as IconFiles } from "../../assets/icons/icon_files.svg";
import { ReactComponent as IconAudience } from "../../assets/icons/icon_audience.svg";
import { ReactComponent as IconLungs } from "../../../assets/icon_lungs.svg";
import { ReactComponent as IconSpeaker } from "../../../assets/icon_speaker.svg";
import { ReactComponent as IconMoney } from "../../../assets/icon_money.svg";

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";

import { toast } from 'react-toastify';


// import Swal from 'sweetalert2';

import {
  Wrapper,
  Header,
  StyledModal,
  Backdrop,
} from '../general/modal.style';
import Loader from '../general/Loader';

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  postSurvey: (startEpoch: number) => void;
}

export const ModalScheduleSurvey: FunctionComponent<ModalProps> = ({
  isShown,
  hide,
  postSurvey
}) => {

  const [startDate, setStartDate] = useState<string>("");
  
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDateString = event.target.value;
    setStartDate(selectedDateString);
  };

  const modalScheduleSurvey = (
    <React.Fragment>
      <Backdrop />
      <Wrapper>
        <StyledModal>
          {/* <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>X</CloseButton>
          </Header> */}
          <div className="modal-schedule-survey">
            <button className="btn-close-modal" onClick={()=>{hide();}}><IconClose /></button>
            <h2 className='heading-m txt-primary'>When do you want to send this survey?</h2>
            <input className="modal-schedule-survey__date" type={'date'} id="deadline-datepicker" value={startDate} onChange={handleDateChange}/>
            <button className="btn btn--accent" onClick={()=>{postSurvey(Math.floor(new Date(startDate).getTime()))}}>Send</button>
          </div>
        </StyledModal>
      </Wrapper>
    </React.Fragment>
  );

  const rootContainer = document.getElementById('root');
  return isShown && rootContainer ? ReactDOM.createPortal(modalScheduleSurvey, rootContainer) : null;
};