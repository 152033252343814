import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as StartQuestIcon } from "../../assets/icons/advices/icon-start-quest.svg";


// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    HeaderText,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import { SurveyListItem } from './SurveyListItem';
import IconsAdvices from '../general/IconsAdvices';
import { toast } from 'react-toastify';
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    advice: any;
    questResults: any;
}

export const ModalQuestResults: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    advice,
    questResults,
}) => {
    const modalQuestResults = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    {(!advice || !questResults) ? ("No Advice or QuestResults") : (
                        <div className="modal-quest">
                            <div className='modal-quest__header' style={{ ["--metrics-color" as any]: advice.metricColor }}>
                                {IconsAdvices[advice.categoryIcon] && React.createElement(IconsAdvices[advice.categoryIcon])}
                                <div className='modal-quest__header__info'>
                                    <h2>{advice.metricCategory}</h2>
                                    <p>{advice.metricAdvice}</p>
                                </div>

                                <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                            </div>
                            <div className='modal-quest__body'>
                                <h2>Let's take a look at how you've done:</h2>
                                <ResponsiveContainer width="100%" height="100%" aspect={1}>
                                    <LineChart
                                        data={questResults.averages}
                                    >
                                        <XAxis
                                            tick={false}
                                        />
                                        <YAxis
                                            tick={{ fill: advice.metricColor }}
                                            ticks={[questResults.averages[0].target]}
                                        />
                                        <Legend iconType={"plainline"} />
                                        <Line
                                            type="monotone"
                                            dataKey="target"
                                            stroke={advice.metricColor}
                                            dot={false}
                                            strokeWidth={2}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="average"
                                            stroke="#01195D"
                                            dot={false}
                                            strokeWidth={2}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>

                                <p>
                                    {questResults.sentiment === 0
                                        ? "Congratulations on your quest progress! We've noted positive growth from the start. Let's continue this upward trend and explore more avenues for development."
                                        : questResults.sentiment === 1
                                            ? "Quest completed! While your score remains steady, it's a foundation to build upon. Every journey has plateaus; our next step is to climb higher together."
                                            : questResults.sentiment === 2
                                                ? "You've finished your quest, and while there were challenges, setbacks can guide us to grow stronger. Let's reflect and adapt for a better outcome next time."
                                                : 'Good job!'}
                                </p>
                            </div>
                            <div className='modal-quest__footer'>
                                <button onClick={hide} style={{ ["--metrics-color" as any]: advice.metricColor }}><StartQuestIcon /> Finish!</button>
                            </div>
                        </div>
                    )}
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalQuestResults, rootContainer) : null;
};