import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as IconClose } from "../../assets/icons/icon_cross.svg";
import { ReactComponent as IconCheckmark } from "../../assets/icons/icon-checkmark.svg";
import { ReactComponent as IconNext } from "../../assets/icons/icon_arrow_right_circle_empty.svg";
import { ReactComponent as IconPrev } from "../../assets/icons/icon_arrow_left_circle_empty.svg";
import { ReactComponent as IconTopic } from "../../assets/icons/topics/icon-topic.svg";
import { ReactComponent as IconQuestionList } from "../../assets/icons/topics/icon-questionlist.svg";
import { ReactComponent as IconTopic2 } from "../../assets/icons/topics/icon-topic-2.svg";
import { ReactComponent as IconCategory } from "../../assets/icons/topics/icon-category.svg";
import { ReactComponent as IconPriority } from "../../assets/icons/topics/icon-priority.svg";
import { ReactComponent as IconDropDownOpen } from "../../assets/icons/dropdown_open.svg";
import { ReactComponent as IconDropDownClose } from "../../assets/icons/dropdown_close.svg";

import { toast } from 'react-toastify';

// import Swal from 'sweetalert2';

import {
    Wrapper,
    Header,
    StyledModal,
    CloseButton,
    Content,
    Backdrop,
} from '../general/modal.style';
import Loader from '../general/Loader';

export interface ModalProps {
    isShown: boolean;
    hide: () => void;
    token: string;
    categories: [];
    refreshTopics: () => void;
}

export const ModalHRNewTopic: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    token,
    categories,
    refreshTopics
}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [topicTitle, setTopicTitle] = useState("");
    const [loading, setLoading] = useState<Boolean>(false);
    const [selectedCategory, setselectedCategory] = useState<number>(0);
    const [selectedPriority, setSelectedPriority] = useState<number>(0);
    const [questions, setQuestions] = useState([
        {
            question: "New question",
            answerType: 1,
        }
    ]);
    const [answerTypes, setAnswerTypes] = useState([]);
    const [openAccordion, setOpenAccordion] = useState<number | null>(null);
    console.log("SELECTED PRIO IS " + selectedPriority);

    useEffect(() => {
        getAnswerTypes();
    }, [])

    const toggleAccordion = (index: number) => {
        console.log("toggling accordion" + index);
        setOpenAccordion((prevOpen) => (prevOpen === index ? null : index));
    };

    const handleCategoryChange = (event: any) => {
        setselectedCategory(event.target.value);
    };

    const handlePriorityChange = (priority: number) => {
        setSelectedPriority(priority);
    };
    const handleTabClick = (tab: number) => {
        setActiveTab(tab);
    };

    const handleQuestionChange = (value: any, index: any) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index].question = value;
            return updatedQuestions;
        });
    };

    const handleAnswerTypeChange = (answerTypeId: any, index: any) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[index].answerType = answerTypeId;
            return updatedQuestions;
        });
    };


    const getAnswerTypes = async () => {
        try {
            const options = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await fetch(
                `https://mindy-api.azurewebsites.net/answer/types`,
                options
            );
            const responseData = await response.json();
            console.log(responseData);

            setAnswerTypes(responseData.answerTypes);

        } catch (error) {
            console.error("Error fetching answer types", error);
        }
    };

    const postTopic = async () => {
        try {
            const options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    name: topicTitle,
                    questions: questions[0].question === "New question" ? [] : questions,
                    priority: selectedPriority,
                    categoryId: selectedCategory
                })
            };
            const response = await fetch(
                `https://mindy-api.azurewebsites.net/Topics`,
                options
            );

            if (!response.ok) {
                throw new Error(`Server returned ${response.status}`);
            }
            const responseData = await response.json();
            console.log(responseData);

            toast.success("Topic creation successful!");
            refreshTopics();
            hide();
        } catch (error) {
            toast.error("Creating Topic failed");
            console.error("Error fetching answer types", error);
        }
    };

    const addEmptyQuestion = () => {
        setQuestions([...questions, { question: "New question", answerType: 1 }]);
    };

    const handleDeleteQuestion = (index: number) => {
        setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions.splice(index, 1);
            return updatedQuestions;
        });
    };

    const modalHRNewTopic = (
        <React.Fragment>
            <Backdrop />
            <Wrapper>
                <StyledModal>
                    <div className="modal-hr-new-topic">
                        <button className="btn-close-modal" onClick={hide}><IconClose /></button>
                        {activeTab === 0 ? (
                            <IconNext
                                className='modal-hr-new-topic__btn-next'
                                onClick={() => handleTabClick(1)}
                            />
                        ) : (
                            <IconPrev
                                className='modal-hr-new-topic__btn-prev'
                                onClick={() => handleTabClick(0)}
                            />
                        )}
                        {(loading) ? (<Loader />) : (
                            <>
                                <ul className='modal-hr-new-topic__header'>
                                    <li
                                        className={`modal-hr-new-topic__header__tab ${activeTab === 0 ? 'active' : ''
                                            }`}
                                        onClick={() => handleTabClick(0)}
                                    >
                                        <IconTopic /> Topic
                                    </li>
                                    <li
                                        className={`modal-hr-new-topic__header__tab ${activeTab === 1 ? 'active' : ''
                                            }`}
                                        onClick={() => handleTabClick(1)}
                                    >
                                        <IconQuestionList /> QuestionList
                                    </li>
                                </ul>
                                <div className='modal-hr-new-topic__body'>
                                    {activeTab === 0 ? (
                                        <>
                                            <h1 className='modal-hr-new-topic__body__title'>Create your topic.</h1>
                                            <div className='modal-hr-new-topic__body__item'>
                                                <label className='modal-hr-new-topic__body__item__label'><IconTopic2 />Fill in a topic name*</label>
                                                <input placeholder='Give the topic a name...' value={topicTitle} onChange={(e) => setTopicTitle(e.target.value)} />
                                            </div>
                                            <hr />
                                            <div className='modal-hr-new-topic__body__item'>
                                                <label className='modal-hr-new-topic__body__item__label'><IconCategory /> Select main category*</label>
                                                <select value={selectedCategory} onChange={handleCategoryChange} >
                                                    <option value={0}>Select a category</option>
                                                    {(!categories) ? (<option>Loading...</option>) : (
                                                        categories.map((category: any) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>
                                            <hr />
                                            <div className='modal-hr-new-topic__body__item'>
                                                <label className='modal-hr-new-topic__body__item__label'><IconPriority /> Priority</label>
                                                <label className='priority' htmlFor='priority-low'>
                                                    <input
                                                        type="radio"
                                                        id="priority-low"
                                                        name="priority"
                                                        value="low"
                                                        checked={selectedPriority === 0}
                                                        onChange={() => handlePriorityChange(0)}
                                                    />
                                                    <div className={"priority__rect " + (selectedPriority === 0 ? "low" : "")}></div>
                                                    Low
                                                </label>
                                                <label className='priority' htmlFor='priority-medium'>
                                                    <input
                                                        type="radio"
                                                        id="priority-medium"
                                                        name="priority"
                                                        value="medium"
                                                        checked={selectedPriority === 1}
                                                        onChange={() => handlePriorityChange(1)}
                                                    />
                                                    <div className={"priority__rect " + (selectedPriority === 1 ? "medium" : "")}></div>
                                                    Medium
                                                </label>
                                                <label className='priority' htmlFor='priority-high'>
                                                    <input
                                                        type="radio"
                                                        id="priority-high"
                                                        name="priority"
                                                        value="high"
                                                        checked={selectedPriority === 2}
                                                        onChange={() => handlePriorityChange(2)}
                                                    />
                                                    <div className={"priority__rect " + (selectedPriority === 2 ? "high" : "")}></div>
                                                    High
                                                </label>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='modal-hr-new-topic__body__header'>
                                                <h1 className='modal-hr-new-topic__body__title'>
                                                    {topicTitle} questions
                                                    <span>({questions.length})</span>
                                                </h1>
                                                <button className='modal-hr-new-topic__add-question-btn' onClick={addEmptyQuestion}>+</button>
                                            </div>

                                            <div className='modal-hr-new-topic__question-list'>
                                                {
                                                    questions.map((question: any, index: number) => (
                                                        <div className='modal-hr-new-topic__question'>
                                                            {openAccordion === index ? (
                                                                <div className='modal-hr-new-topic__question__header' >
                                                                    <input className='modal-hr-new-topic__question__name' placeholder='Write your question' value={question.question} onChange={(e) => handleQuestionChange(e.target.value, index)} />
                                                                    <div className='modal-hr-new-topic__question__controls'>
                                                                        <IconDropDownOpen style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} onClick={() => toggleAccordion(index)} />
                                                                        <button className='modal-hr-new-topic__question__controls__save' onClick={() => toggleAccordion(index)}><IconCheckmark /></button>
                                                                        <button className='modal-hr-new-topic__question__controls__delete' onClick={() => handleDeleteQuestion(index)}><IconClose /></button>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='modal-hr-new-topic__question__header' >
                                                                    <h2>{question.question}</h2>
                                                                    <div className='modal-hr-new-topic__question__controls'>
                                                                        <IconDropDownOpen style={{ cursor: 'pointer' }} onClick={() => toggleAccordion(index)} />
                                                                        <button className='modal-hr-new-topic__question__controls__delete' onClick={() => handleDeleteQuestion(index)}><IconClose /></button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className={`modal-hr-new-topic__question__body ${openAccordion === index ? 'open' : ''}`}>
                                                                <p className='modal-hr-new-topic__question__body__title'>Type of answer:</p>
                                                                {
                                                                    openAccordion !== null && openAccordion !== undefined &&
                                                                    answerTypes.map((answerType: any, index: number) => (
                                                                        <div
                                                                            className={`modal-hr-new-topic__question__body__answer-list ${questions[openAccordion].answerType === answerType.id
                                                                                ? 'active'
                                                                                : ''
                                                                                }`}
                                                                            key={index}
                                                                            onClick={() => handleAnswerTypeChange(answerType.id, openAccordion)}
                                                                        >
                                                                            {answerType.answers.map((answer: any, index: number) => (
                                                                                <p
                                                                                    key={index}
                                                                                    className='modal-hr-new-topic__question__body__answer'
                                                                                >
                                                                                    {answer.description}
                                                                                </p>
                                                                            ))}
                                                                        </div>
                                                                    ))
                                                                }

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )}

                                </div>
                                <div className='modal-hr-new-topic__footer'>
                                    <button className='modal-hr-new-topic__footer__btn-delete' onClick={hide}>Delete</button>
                                    <button className='modal-hr-new-topic__footer__btn-save' onClick={postTopic}>Save</button>
                                </div>
                            </>
                        )}

                    </div>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    const rootContainer = document.getElementById('root');
    return isShown && rootContainer ? ReactDOM.createPortal(modalHRNewTopic, rootContainer) : null;
};