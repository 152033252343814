import { HashRouter as Router, Route, Routes, useParams } from "react-router-dom";
import { useState, useEffect } from 'react'

import { ReactComponent as WelcomeWizardImageLg } from "../../assets/img/img-welcome-wizard-lg.svg";
import { ReactComponent as LogoBg } from "../../assets/img/img-logo-bg.svg";
import { ReactComponent as Prev } from "../../assets/icons/icon_arrow_left_circle_empty.svg";
import { ReactComponent as Next } from "../../assets/icons/icon_arrow_right_circle_empty.svg";
import { Stepper, Step } from 'react-form-stepper';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify'
function Survey(props: any) {
    const navigate = useNavigate();
    const { surveyId } = useParams();
    const [survey, setSurvey] = useState<any>();
    const [questions, setQuestions] = useState<any>();
    const [question, setQuestion] = useState<any>();
    const [a1IsChecked, setA1IsChecked] = useState(false);
    const [a2IsChecked, setA2IsChecked] = useState(false);
    const [a3IsChecked, setA3IsChecked] = useState(false);
    const [a4IsChecked, setA4IsChecked] = useState(false);
    const [a5IsChecked, setA5IsChecked] = useState(false);
    const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

    useEffect(() => {
        getSurvey();
    }, []);

    // const getSurvey = async () => {
    //     var options = {
    //         method: 'GET',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + props.token,
    //         },
    //     }

    //     const response = await fetch("https://mindy-api.azurewebsites.net/Survey?surveyId=" + surveyId, options);
    //     const data = await response.json();

    //     console.log(response);
    //     console.log(data);

    //     setSurvey(data.surveys[0]);

    //     setQuestion(data.surveys[0].questions[0])
    // }

    const getSurvey = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/Survey?surveyId=" + surveyId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);

        setSurvey(data.surveys[0]);
        setQuestions(data.surveys[0].questions);
        const questionsData = data.surveys[0].questions;

        // Find the first question where done is false
        const firstUnansweredQuestion = questionsData.find((question: any) => !question.done);

        if (firstUnansweredQuestion) {
            setQuestion(firstUnansweredQuestion);
        } else {
            // All questions are answered, navigate or handle as needed
        }
    }

    async function next() {
        if (selectedAnswer === null) {
            toast.error("Please select an answer!")
        } else {
            console.log("next");
            let nextNumber = questions.indexOf(question) + 1;
            console.log(nextNumber);
            console.log("length: " + questions.length);
            var answer = {
                score: selectedAnswer,
                questionId: question.id,
                surveyId: survey.id
            }
            try {
                var options = {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + props.token,
                    },
                    body: JSON.stringify(answer)
                }

                const response = await fetch("https://mindy-api.azurewebsites.net/Survey", options);

                if (!response.ok) {
                    throw new Error(`Request failed with status: ${response.status}`);
                }

            } catch (error) {
                // Handle the error here
                console.error(error);
                toast.error("Answer error!");
            }

            if (nextNumber >= questions.length) {
                navigate("/");
            } else {
                setSelectedAnswer(null);
                setQuestion(questions[nextNumber]);
            }
        }

    }
    function prev() {
        console.log("prev");
        let prevNumber = questions.indexOf(question) - 1;
        console.log(prevNumber)
        if (prevNumber === -1) {
            navigate("/");
        } else {
            setQuestion(questions[prevNumber]);
        }
    }

    const handleAnswerChange = (score: number) => {
        setSelectedAnswer(score);
    };

    return (
        <div className="survey">
            <WelcomeWizardImageLg className="survey__bg" />
            <LogoBg className="survey__bg--logo" />
            {/* <div className="survey__stepper">
                <h2 className="survey__stepper__title">Overview</h2>
                <Stepper connectorStateColors={true} connectorStyleConfig={{ size: 2, disabledColor: "#FFFFFF", activeColor: "#01195D", completedColor: "#01195D", style: 'solid' }} className="welcome-wizard__stepper" stepClassName="welcome-wizard__stepper__step" activeStep={1}>
                    <Step label="Introduction" />
                    <Step label="Survey" />
                    <Step label="Preferences" />
                </Stepper>
            </div> */}

            <div className="survey__container">
                {(question === undefined) ? (
                    <h2 className="survey__container__title">Loading Survey...</h2>
                ) : (
                    <>
                        <h2 className="survey__container__title">Question #{questions.indexOf(question) + 1}/{questions.length}</h2>
                        <p className="survey__container__question">{question.question}</p>
                        <div className="survey__container__answers">
                            {(!question.answers) ? ("No answers") : (
                                question.answers.map((answer: any) => (
                                    <div key={answer.id}>
                                        <input type="radio" id={"a1q" + answer.id} name={"question" + question.id} checked={selectedAnswer === answer.score} value={answer.score} onChange={() => handleAnswerChange(answer.score)} />
                                        <label htmlFor={"a1q" + answer.id}>{answer.description}</label>
                                    </div>
                                ))
                            )}

                            {/* <input type="radio" id={"a2q" + question.id} name={"question" + question.id} value="2" checked={a2IsChecked} onChange={(event) => setA2IsChecked(event.target.checked)} />
                            <label htmlFor={"a2q" + question.id}>Niet mee eens</label>
                            <input type="radio" id={"a3q" + question.id} name={"question" + question.id} value="3" checked={a3IsChecked} onChange={(event) => setA3IsChecked(event.target.checked)} />
                            <label htmlFor={"a3q" + question.id}>Niet mee eens en niet mee oneens</label>
                            <input type="radio" id={"a4q" + question.id} name={"question" + question.id} value="4" checked={a4IsChecked} onChange={(event) => setA4IsChecked(event.target.checked)} />
                            <label htmlFor={"a4q" + question.id}>Mee eens</label>
                            <input type="radio" id={"a5q" + question.id} name={"question" + question.id} value="5" checked={a5IsChecked} onChange={(event) => setA5IsChecked(event.target.checked)} />
                            <label htmlFor={"a5q" + question.id}>Helemaal mee eens</label> */}
                        </div>
                        <div className="survey__container__actions">
                            <div className='survey__container__actions--prev' onClick={prev}>
                                <Prev />
                                <p>{questions.indexOf(question) === 0 ? ("Back") : ("Previous question")}</p>
                            </div>
                            <div className='survey__container__actions--next' onClick={next}>
                                <p>{questions.indexOf(question) === questions.length - 1 ? ("Finish survey") : ("Next question")}</p>
                                <Next />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Survey;
