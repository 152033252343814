import { useEffect, useState } from 'react'
import { SurveyListItem } from "./SurveyListItem";
import { useModal } from "../general/useModal";
import { MetricCategory } from "./MetricCategory";
import { ModalSurveyList } from "./ModalSurveyList";

function SurveyMetrics(props: any) {
    
    const [user, setUser] = useState<any>(props.user)
    const [activeTab, setActiveTab] = useState(0);
    const { isShown, toggle } = useModal();
    const [surveys, setSurveys] = useState<any>();
    const [categories, setCategories] = useState<any>();
    const [topics, setTopics] = useState<any>();
    const { isShown: isModalSurveyListShown, toggle: toggleSurveyListModal } = useModal();

    useEffect(() => {
        getSurveys();
    }, [])

    const handleTabClick = (index: number) => {
        setActiveTab(index);
        setTopics(categories[index]?.topics || []);
    };

    const getSurveys = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/Survey?userId=" + user.userId, options);
        const data = await response.json();

        console.log(response);
        console.log(data);
        setSurveys(data.surveys);
        setCategories(data.categories);
        setTopics(data.categories[0]?.topics || []);
    }

    return (
        <div className="hr-platform">
            <section className="hr-platform__surveys-container | bg-white">
                <div className="header">
                    <h2 className="header__title | heading-l txt-primary">Want some more insights?</h2>
                    <p className="header__text | txt-lightgrey">You can gather more information about all your surveys by choosing a couple subjects of interest.</p>
                </div>
                <div className="content">
                    <ul className="survey-list">
                        {(!surveys) ? ("Loading...") : (surveys.length === 0) ? ( <p style={{ alignSelf: "center" }}>You havent got any surveys yet.</p>) : (
                            surveys.slice(0, 4).map((survey: any, index:any) => {
                                return <SurveyListItem key={index} data={survey} token={props.token} userId={user.userId}/>
                                // return <MetricCategory key={item.id} data={item} />
                            })
                        )}
                    </ul>
                    {/* TODO: add button and surveylist modal(no design yet) */}
                    <button className="btn-view-all" onClick={toggleSurveyListModal}>View all</button>
                    <ModalSurveyList isShown={isModalSurveyListShown} hide={toggleSurveyListModal} token={props.token} surveys={surveys} userId={user.userId}/>
                </div>
            </section>

            <section className="hr-platform__metrics-container">
                <div className="header">
                    <h2 className="header__title | heading-l txt-primary">Your Wellbeing <span className="txt-hr">Topics</span></h2>
                    <ol className="metrics-tabs">
                        {/* <li className={(activeTab === 0) ? ("metrics-tabs__title active") : ("metrics-tabs__title")} onClick={() => setActiveTab(0)}>Professional well-being</li>
                        <li className={(activeTab === 1) ? ("metrics-tabs__title active") : ("metrics-tabs__title")} onClick={() => setActiveTab(1)}>Social well-being</li>
                        <li className={(activeTab === 2) ? ("metrics-tabs__title active") : ("metrics-tabs__title")} onClick={() => setActiveTab(2)}>Physical and Emotional well-being</li>
                        <li className={(activeTab === 3) ? ("metrics-tabs__title active") : ("metrics-tabs__title")} onClick={() => setActiveTab(3)}>Psychological resources</li> */}
                        {(!user || !categories) ? (<li className="metrics-tabs__title active">Loading</li>) : (
                            categories.map((category: any, index: number) => (
                                <li
                                    key={category.id}
                                    className={activeTab === index ? "metrics-tabs__title active" : "metrics-tabs__title"}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {category.name}
                                </li>
                            ))
                        )}

                    </ol>
                </div>
                <div className="content">
                    {(!user || !topics) ? ("Loading...") : (
                        topics.map((topic: any) => (
                            <MetricCategory key={topic.id} data={topic} />
                        ))
                    )}
                    {/* {MetricCategories.map((item: any) => {
                        return <MetricCategory key={item.id} data={item} />
                    })} */}
                </div>
            </section>
        </div>
    )
}

export default SurveyMetrics