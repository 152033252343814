import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react'
import { ReactComponent as WelcomeWizardImageLg } from "../../assets/img/img-welcome-wizard-lg.svg";
import { ReactComponent as LogoBg } from "../../assets/img/img-logo-bg.svg";
import { toast } from 'react-toastify';
import VideoPlayer from "../general/VideoPlayer";

function Introduction(props: any) {
    const navigate = useNavigate();

    const [privacyIsChecked, setPrivacyIsChecked] = useState(false);
    const [consentIsChecked, setConsentIsChecked] = useState(false);
    const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");

    useEffect(() => {
        getPrivacyPolicy();
    }, [])

    const getPrivacyPolicy = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/company/privacypolicy", options);

        // Check if the response is ok
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json()
        console.log("privacy policy link: " + JSON.stringify(data));
        setPrivacyPolicyLink(data.url);
        
    }

    function setUserConsent() {
        if (!privacyIsChecked) {
            toast.error('Please accept the privacy consent', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if (!consentIsChecked) {
            toast.error('Please accept the office information consent', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else if (!props.user.hasBotPermission) {
            toast.info('Please accept Mindy Assistant permissions', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            var user = {
                privacyConsent: true
            }
            var options = {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + props.token,
                },
                body: JSON.stringify(user)
            }
            fetch('https://mindy-api.azurewebsites.net/Employee', options)
                .then(function (res) {
                    console.log(res.json());
                })
                .then(function (resJson) {
                    console.log(resJson);
                    navigate("/welcome-wizard/survey");
                })
        }
    }

    return (
        <div className="introduction">
            <WelcomeWizardImageLg className="introduction__bg" />
            <LogoBg className="introduction__bg--logo" />
            <div className="introduction__stepper">
                {/* <h2 className="introduction__stepper__title">Overview</h2> */}
                {/* <Stepper connectorStateColors={true} connectorStyleConfig={{size:2, disabledColor: "#FFFFFF", activeColor: "#01195D", completedColor: "#01195D", style:'solid'}} className="welcome-wizard__stepper" stepClassName="welcome-wizard__stepper__step" activeStep={0}>
                    <Step label="Introduction"/>
                    <Step label="Survey"/>
                    <Step label="Preferences"/>
                </Stepper> */}
            </div>

            <div className="introduction__container">
                <h2 className="introduction__container__title">What will happen with your data?</h2>
                {/* <div className="introduction__container__video-wrapper">
                    <video controls>
                        <source src={video} type="video/mp4"/>
                        Your browser does not support HTML video.
                    </video>
                </div> */}
                {/* <VideoPlaceholder className="introduction__container__video"/> */}
                <VideoPlayer />
                {/* <label className="introduction__container__label"><input className="introduction__container__checkbox" type={"checkbox"} checked={privacyIsChecked} onChange={(event) => setPrivacyIsChecked(event.target.checked)}/>I have read and accept the <a href="https://mindthriven.zohodesk.eu/portal/en/kb/articles/privacy-notice" target="_blank">privacy policy</a>.</label> */}
                <label className="introduction__container__label"><input className="introduction__container__checkbox" type={"checkbox"} checked={privacyIsChecked} onChange={(event) => setPrivacyIsChecked(event.target.checked)} />I have read and accept the {(privacyPolicyLink === "") ? ("no privacy policy found") : (<a href={privacyPolicyLink} target="_blank">privacy policy</a>)}.</label>
                <label className="introduction__container__label"><input className="introduction__container__checkbox" type={"checkbox"} checked={consentIsChecked} onChange={(event) => setConsentIsChecked(event.target.checked)} />I give consent to access my M365 information.</label>
                {/* <p>{(props.user.hasBotPermission)}</p> */}
                <button className="introduction__container__btn" onClick={setUserConsent}>Start my journey</button>
            </div>
        </div>
    )
}

export default Introduction
