import { HashRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import * as Sentry from "@sentry/react";
import { ReactComponent as EnergyDashboardIcon } from "../assets/icons/icon-energy-dashboard.svg";
import { ReactComponent as WorkBehaviourIcon } from "../assets/icons/icon-work-behaviour.svg";
import { ReactComponent as SentimentIcon } from "../assets/icons/icon-sentiment.svg";
import { ReactComponent as SurveyMetricsIcon } from "../assets/icons/icon-survey-metrics.svg";
import EnergyDashboard from "./home/EnergyDashboard";
import WorkBehaviour from "./home/WorkBehaviour";
import Sentiment from "./home/Sentiment";
import SurveyMetrics from "./home/SurveyMetrics";
import { toast } from "react-toastify";

function Home2(props: any) {
    const [activeTab, setActiveTab] = useState(0);
    const [user, setUser] = useState<any>();
    const navigate = useNavigate();


    useEffect(() => {
        getUser();
    }, [])

    const getUser = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/User", options);
        const data = await response.json();

        Sentry.setUser({id: data.userId});

        if (data.userId === -1) {
            navigate("/welcome-wizard");
            toast.success("Please create your account.");
            props.setUser(data);
        } else if (data.privacyConsent === null) {
            toast.success("Please accept the privacy consent.");
            props.setUser(data);
            navigate("/welcome-wizard/introduction");
        } else if (!data.onboardingCompleted) {
            toast.success("Please fill in our welcome survey.");
            props.setUser(data);
            navigate("/welcome-wizard/survey");
        } else if (data.productiveHours === null) {
            toast.success("Please fill in your preferences.");
            props.setUser(data);
            navigate("/welcome-wizard/preferences");
        } else {
            console.log(response);
            console.log(data);
            setUser(data);
            props.setUser(data);
        }
    }

    return (
        <main className="hr">
            <ol className="tabs">
                <li className={(activeTab === 0) ? ("tabs__title active") : ("tabs__title")} onClick={() => setActiveTab(0)}><EnergyDashboardIcon />{window.matchMedia("(max-width: 767px)").matches ? 'Energy' : 'Energy Dashboard'}</li>
                <li className={(activeTab === 1) ? ("tabs__title active") : ("tabs__title")} onClick={() => setActiveTab(1)}><WorkBehaviourIcon />{window.matchMedia("(max-width: 767px)").matches ? 'Behaviour' : 'Work Behaviour'}</li>
                <li className={(activeTab === 2) ? ("tabs__title active") : ("tabs__title")} onClick={() => setActiveTab(2)}><SentimentIcon />Sentiments</li>
                <li className={(activeTab === 3) ? ("tabs__title active") : ("tabs__title")} onClick={() => setActiveTab(3)}>
                    <SurveyMetricsIcon />
                    {window.matchMedia("(max-width: 767px)").matches ? 'Survey & Metrics' : 'Wellbeing Surveys'}
                    {(user && user.openSurveys > 0) && (
                        <span className="badge">{user.openSurveys}</span>
                    )}
                </li>
            </ol>
            <div className="tab-content">

                {(!user) ? (
                    "Loading"
                ) : (activeTab === 0) ? (
                    <EnergyDashboard token={props.token} user={user} />
                ) : (activeTab === 1) ? (
                    <WorkBehaviour token={props.token} user={user} />
                ) : (activeTab === 2) ? (
                    <Sentiment token={props.token} user={user} />
                ) : (activeTab === 3) ? (
                    <SurveyMetrics token={props.token} user={user} />
                ) : (
                    <EnergyDashboard />
                )
                }
            </div>
        </main>
    )
}

export default Home2
