import { useEffect, useState } from 'react'
import { ReactComponent as DailyCheckinIcon } from "../assets/icons/icon-mm-daily-checkin.svg";
import { ReactComponent as ActivityReportIcon } from "../assets/icons/icon-mm-activity-report.svg";
import { ReactComponent as MMIcon } from "../assets/icons/icon-mm.svg";
import DailyCheckin from "./mindful-morning/DailyCheckin";
import ActivityReport from "./mindful-morning/ActivityReport";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function MindfulMorning(props: any) {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(0);
    const [selectedEmotions, setSelectedEmotions] = useState<any>([]);
    const [selectedScore, setSelectedScore] = useState<any>();
    const [user, setUser] = useState<any>();

    console.log("selectedEmotions: " + JSON.stringify(selectedEmotions));

    useEffect(() => {
        getUser();
    }, [])

    const getUser = async () => {
        var options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token,
            },
        }

        const response = await fetch("https://mindy-api.azurewebsites.net/User", options);
        const data = await response.json();


        setUser(data);
    }

    function continueToActivityReport() {
        if (!selectedScore) {
            toast.error("Please select a score.")
        } else if (selectedEmotions.length === 0) {
            toast.error("Please select an emotion.");
        } else {
            setActiveTab(1);
        }

    }

    const addSelectedEmotion = (emotionId: any) => {
        console.log("adding selected emotion id: " + emotionId);
        setSelectedEmotions((prevSelectedEmotions: any) => [...prevSelectedEmotions, emotionId]);
    };

    const removeSelectedEmotion = (emotionId: any) => {
        console.log("removing selected emotion id: " + emotionId);
        setSelectedEmotions((prevSelectedEmotions: any) =>
            prevSelectedEmotions.filter((item: any) => item !== emotionId)
        );
    };

    return (
        <main className="mindful-morning">
            {(!user) ? ("") : (
                <>
                    {(user.mindfulMorningCompleted) ? (<><p>You've already completed your mindful morning, please come back tomorrow!"</p>
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                        }}>Go back &#8250;</a></>) : (
                        <>
                            <nav className="mindful-morning__nav">
                                <MMIcon onClick={() => { navigate("/"); }} style={{ cursor: 'pointer' }} />
                                <ol className="mindful-morning__nav__tabs">
                                    <li className={(activeTab === 0) ? ("mindful-morning__nav__tabs__title active") : ("mindful-morning__nav__tabs__title line-through")} onClick={() => setActiveTab(0)}><DailyCheckinIcon />Daily Check-In</li>
                                    <li className={(activeTab === 1) ? ("mindful-morning__nav__tabs__title active") : ("mindful-morning__nav__tabs__title")}><ActivityReportIcon />Activity Report</li>
                                </ol>
                            </nav>
                            {(activeTab === 0) ? (
                                <DailyCheckin token={props.token} onClickContinueBtn={continueToActivityReport} username={props.username} addSelectedEmotion={addSelectedEmotion} removeSelectedEmotion={removeSelectedEmotion} selectedEmotions={selectedEmotions} selectedScore={selectedScore} setSelectedScore={setSelectedScore} user={user} />
                            ) : (
                                <ActivityReport token={props.token} platformId={props.platformId} selectedEmotions={selectedEmotions} selectedScore={selectedScore} />
                            )}
                        </>
                    )}
                </>
            )}

        </main>
    )
}

export default MindfulMorning
